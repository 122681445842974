// src/App.tsx
import React, { useState, useEffect } from 'react';
import { Button, Modal, message } from 'antd';
import ArtForm from './_components/ArtForm';
import { addAD, modifyAD, getAD, removeArtwork, } from '../../../api';
import { useParams, useNavigate } from 'react-router-dom';
import DataTable from './_components/DataTable';
import NavigationButtons from '../_components/NavigationButtons';

function AD() {
  const [isVisible, setIsVisible] = useState(false);
  const [initialData, setInitialData] = useState<any>({});
  const [arts, setArts] = useState([]);
  const [mode, setMode] = useState<'edit' | 'create'>('create');
  const navigate = useNavigate()
  const loadData = () => {
    getAD().then((res) => {
      console.log('res', res);
      setArts(res?.data)
    }).catch().finally()
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (!isVisible) {
      setInitialData({})
    }
  }, [isVisible])

  const handleSave = async (data: any) => {
    // 这里处理保存逻辑，比如发送到后端等。
    try {
      setIsVisible(false);
      await addAD({ ...data })
      loadData()
    } catch (error) {
      console.log('error', error);
    }

  };

  return (
    <div className="Artwork bg-white min-h-[100vh] pb-[100px]">
      <NavigationButtons />
      <Button className='m-2' onClick={() => { navigate('/admin') }}>Back</Button>
      <Button className='m-2' onClick={() => { setMode('create'); setIsVisible(true); }}>Create Ad</Button>
      <div className='m-2'>
        <DataTable
          data={arts}
          onEdit={async (record: any) => {
            await setInitialData(record)
            await setMode('edit');
            setIsVisible(true);
          }}
          onDelete={(id: any) => {
            modifyAD({ id, status: 0 }).then((res) => {
              message.info(res?.data?.msg)
              loadData()
            })
          }}
        />
      </div>
      <Modal
        open={isVisible}
        title={mode === 'create' ? 'Create AD' : 'Edit AD'}
        onCancel={() => setIsVisible(false)}
        footer={null}
      >
        <ArtForm
          mode={mode}
          initialData={initialData}
          onSave={handleSave}
        />
      </Modal>
    </div>
  );
}

export default AD;
