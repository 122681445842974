import React from 'react';

interface BackGroundProps { }

const BackGround: React.FC<BackGroundProps> = () => {
  return (
    <div className="fixed left-0 top-0 height-fill-available md:min-h-screen max-h-screen md:h-screen w-screen overflow-hidden z-[-1] bg-black">
    </div>
  );
};

export default BackGround;

