// src/MyForm.tsx
import React from 'react';
import { Form, Input, Button } from 'antd';

interface Props {
  onSave: (data: any) => void;
  initialData?: any;
}

const TagsForm: React.FC<Props> = ({ onSave, initialData = {} }) => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    onSave(values);
  };
  return (
    <Form
      form={form}
      initialValues={initialData}
      onFinish={onFinish}
    >
      <Form.Item name="tag" label="tag" rules={[{ required: true, message: 'Please input the tag!' }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className='text-black'>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TagsForm;
