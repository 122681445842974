import React, { useState, useEffect, useCallback } from 'react';
import { getArtistsCoverByRandom } from '../api';
import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

interface MediaCover {
  id: number;
  cover: string;
  name: string;
  price: string;
  type?: string;
}

interface CastCoverCardProps {
  media: MediaCover;
  index: number;
  showName?: boolean;
  setShowPreview?: (flag: boolean) => void;
  setCurrentIndex?: (flag: number) => void;
}

const CastCoverCard: React.FC<CastCoverCardProps> = ({ media, index, showName = true }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <div className="rounded-lg overflow-hidden mb-4 w-full">
      <div className="aspect-w-5 aspect-h-7">
        <img
          className="rounded-lg contrast-115 object-cover w-full h-full"
          src={media?.cover}
          alt={media?.name}
          onLoad={() => setImgLoad(true)}
          onError={() => setImgLoad(false)}
          onClick={() => navigate(`/info/${media?.id}`)}
        />
        {imgLoad ? null : <div className='bg-[#d0c9c9] w-full h-full skeleton rounded-lg'></div>}
      </div>
      {showName && (
        <div className="text-white p-2 text-center mt-2">
          <p className="text-sm sm:text-lg">{media?.name}</p>
          <p className="text-xs sm:text-sm text-gray-400">{media?.price || ''}</p>
        </div>
      )}
    </div>
  );
};

/* interface CastWallProps {
  type?: string;
} */

const CastWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [mediaList, setMediaList] = useState<MediaCover[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [typeFilter, setTypeFilter] = useState<string | undefined>(undefined);

  const loadData = useCallback(async () => {
    setLoading(true);
    const excludedIds = [0];
    const sort = 'rand';
    const limit = isMobile ? 15 : 20;
    const type = searchParams.get('type') || undefined;
    setTypeFilter(type);

    getArtistsCoverByRandom({ orderBy: sort, limit, excludedIds, type }).then((res: any) => {
      const newList = res?.data || [];
      setMediaList(newList);
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }, [isMobile]);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (mediaList?.length === 0 && !loading) {
    return <div className='mt-16'>No Data</div>;
  }

  return (
    <div className={`xplore flex flex-col items-center px-4 ${isMobile ? 'mt-12' : 'mt-36'}`}>
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className="self-center text-xl font-medium text-center text-orange-800">
        新着魚
      </div>
      <div className={`self-center text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl ${isMobile ? 'mt-4' : 'mt-12'}`}>
        New Fish List
      </div>
      <div className={`smedia-list grid grid-cols-3 gap-4 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-4 ${isMobile ? 'mt-6' : 'mt-36'}`}>
        <Image.PreviewGroup>
          {mediaList.map((media, index) => (
            <CastCoverCard
              index={index}
              key={`media${media?.id}`}
              media={media}
              showName={true}
            />
          ))}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default CastWall;
