// src/components/DataTable.tsx
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Table, Space, Button, Image, Popover, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
interface User {
  id: string;
  username: string;
  description: string;
  //avatar: string;
  //banner: string;
  cover:string;
}

interface DataTableProps {
  data: User[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

const DataTable: React.FC<DataTableProps> = ({ data, onDelete, onEdit }) => {
  const navigate = useNavigate()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search name"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <button onClick={() => confirm()}>Search</button>
          <button onClick={() => clearFilters()}>Reset</button>
        </div>
      ),
      onFilter: (value:any, record:any) => record?.username.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },

   /*  {
      title: 'English Name',
      dataIndex: 'englishName',
      key: 'englishName',
    }, */
 
    {
      title: 'Cover',
      dataIndex: 'cover',
      key: 'cover',
      render: (text: string) => (
        <Popover content={<Image width={200} src={text} />}>
          <Image width={50} src={text} />
        </Popover>
      ),
    },
    {
      title: 'Photo No.1',
      dataIndex: 'photo1',
      key: 'photo1',
      render: (text: string) => (
        <Popover content={<Image width={200} src={text} />}>
          <Image width={50} src={text} />
        </Popover>
      ),
    },
    {
      title: 'Photo No.2',
      dataIndex: 'photo2',
      key: 'photo2',
      render: (text: string) => (
        <Popover content={<Image width={200} src={text} />}>
          <Image width={50} src={text} />
        </Popover>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: User) => (
        <Space size="middle">
          <Button onClick={() => onEdit(record as any)}>Edit</Button>
          <Button onClick={() => onDelete(record.id)} danger>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: 'max-content' }}
      pagination={{ 
        pageSizeOptions: ['10', '25', '50'],
        showSizeChanger: true,
      }} 
    />
  );
};

export default DataTable;