// src/EventForm.tsx
import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import ImageUpload from '../../../component/ImageUpload';

interface Props {
  mode: 'edit' | 'create';
  initialData?: {
    id: number;
    title?: string;
    subtitle?: string;
    description?: string;
    cover?: string;
  };
  onSave: (data: any) => void;
}

const EventForm: React.FC<Props> = ({ mode, initialData, onSave }) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    onSave(values);
    form.resetFields();
  };

  const handleImageUploadSuccess = (url: string, fieldName: string) => {
    form.setFieldsValue({
      [fieldName]: url
    });
  };

  useEffect(() => {
    if (initialData?.id) {
      form.setFieldsValue({
        ...initialData,
      });
    }
  }, [initialData]);

  return (
    <Form
      form={form}
      initialValues={initialData}
      onFinish={onFinish}
    >
      {mode === 'edit' ? (
        <Form.Item name="id" label="ID">
          <Input disabled />
        </Form.Item>
      ) : null}
      <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input the title!' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="subtitle" label="Subtitle">
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
        <Input.TextArea />
      </Form.Item>
     {/*  <Form.Item name="en_description" label="En_Description" rules={[{ required: true, message: 'Please input the description!' }]}>
        <Input.TextArea />
      </Form.Item> */}
      <Form.Item name="cover" label="Cover" rules={[{ required: false, message: 'Please upload!' }]}>
        <ImageUpload initialImageUrl={initialData?.cover} onUploadSuccess={(url) => handleImageUploadSuccess(url, 'cover')} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="text-black">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EventForm;

/* // src/EventForm.tsx
import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import ImageUpload from '../../../component/ImageUpload';

interface Props {
  mode: 'edit' | 'create';
  initialData?: {
    id: number;
    description?: string;
    cover?: string;
  };
  onSave: (data: any) => void;
}

const EventForm: React.FC<Props> = ({ mode, initialData, onSave }) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    onSave(values);
    form.resetFields();
  };

  const handleImageUploadSuccess = (url: string, fieldName: string) => {
    form.setFieldsValue({
      [fieldName]: url
    });
  };

  useEffect(() => {
    if (initialData?.id) {
      form.setFieldsValue({
        ...initialData,
      });
    }
  }, [initialData]);

  return (
    <Form
      form={form}
      initialValues={initialData}
      onFinish={onFinish}
    >
      {mode === 'edit' ? (
        <Form.Item name="id" label="id">
          <Input disabled />
        </Form.Item>
      ) : null}
      <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="cover" label="Cover" rules={[{ required: true, message: 'Please upload!' }]}>
        <ImageUpload initialImageUrl={initialData?.cover} onUploadSuccess={(url) => handleImageUploadSuccess(url, 'cover')} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="text-black">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EventForm; */


