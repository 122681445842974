import request from './request';

export const modifyTag = (data: any) => {
  return request({
    url: '/modifyTag',
    method: 'post',
    data
  });
}

export const moveTag = (data: any) => {
  return request({
    url: '/moveTag',
    method: 'post',
    data
  });
}