// src/VideoUpload.tsx
import React, { useEffect, useState } from 'react';
import { message, Upload, Button, Image } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { s3Client, ImageBaseUrl } from '../grocery/s3';

interface VideoUploadProps {
  onUploadSuccess?: (url: string) => void;
  initialVideoUrl?: string;
}

const VideoUpload: React.FC<VideoUploadProps> = ({ onUploadSuccess, initialVideoUrl }) => {
  const [videoUrl, setVideoUrl] = useState<string | undefined>(initialVideoUrl);

  useEffect(() => {
    setVideoUrl(initialVideoUrl);
  }, [initialVideoUrl]);

  // 检查文件是否为视频
  const isVideo = (file: RcFile) => {
    const acceptedVideoTypes = ['video/mp4', 'video/webm']; // 添加其他视频格式
    if (!acceptedVideoTypes.includes(file.type)) {
      message.error('You can only upload video files!');
      return false;
    }
    return true;
  };

  // 文件上传之前的逻辑
  const beforeUpload = async (file: RcFile) => {
    if (!isVideo(file)) {
      return false;
    }
    console.log('beforeUpload', file);
    try {
      const folder = 'videos'; // 存储视频的文件夹
      const bucketName = 'shihoo'; // S3 Bucket名称
      const fileName = Date.now() + file.name; // 文件名
      const remoteFilePath = `${folder}/` + fileName; // 远程文件路径
      const params = {
        Bucket: bucketName,
        Key: remoteFilePath,
        Body: file,
        ACL: 'public-read' as 'public-read', // 设置为public-read，使视频公开可访问
      };
      const res = await s3Client.send(new PutObjectCommand(params));
      if (res?.ETag) {
        message.success(`${file.name} file uploaded successfully`);
        const uploadUrl = `${ImageBaseUrl}/${folder}/${fileName}`; // 更新视频URL
        setVideoUrl(uploadUrl); // 更新视频URL状态
        onUploadSuccess?.(uploadUrl); // 触发上传成功回调
      }
      return false;
    } catch (error) {
      message.error(`${file.name} file upload failed.`);
      return false;
    }
  };

  return (
    <div className='flex flex-col'>
      <Upload
        showUploadList={false}
        beforeUpload={beforeUpload}
      >
        <Button>{videoUrl ? "Reupload" : "Upload Video"}</Button>
      </Upload>
      {videoUrl && <video controls width={320} src={videoUrl} className='m-2'></video>}
    </div>
  );
};

export default VideoUpload;
