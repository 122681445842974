import { useState, useEffect, useCallback } from "react";
import { getEventsCoverByRandom } from '../api';
//import { useNavigate } from "react-router-dom";


type EventProps = {
  title: string;
  eventDate: string;
  imgSrc: string;
  altText: string;
  eventDescC: string;
  eventDescE: string;
  manager: string;
};

const Event: React.FC<EventProps> = ({
  title,
  eventDate,
  imgSrc,
  altText,
  eventDescC,
  eventDescE,
  manager,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <article className="flex gap-10 max-md:flex-col max-md:gap-0">
      <figure className="flex flex-col w-1/2 max-md:ml-0 max-md:w-full">
        <img
          loading="lazy"
          src={imgSrc}
          alt={altText}
          className="grow w-full object-contain aspect-[1.22] max-md:mt-10 max-md:max-w-full"
        />
      </figure>
      <div className="flex flex-col ml-5 w-1/2 max-md:ml-0 max-md:w-full">
        <h2 className="flex flex-col mt-7 font-medium text-orange-100 leading-[56px] max-md:mt-10 max-md:max-w-full">
          <span className={`max-md:max-w-full text-left mt-12 ${isMobile ? 'text-[20px]' : 'text-4xl'}`}>{title}</span>
          <div className="flex flex-col self-end mt-5 mr-7 max-w-full w-[389px] max-md:mr-2.5">
            {/* <div className="self-end max-w-full border-fuchsia-600 border-solid aspect-[100] border-[3px] w-[283px]" /> */}
            <div
              className="self-end max-w-full border-solid aspect-[100] w-[283px]"
              style={{
                borderWidth: '3px',
                borderImage: 'linear-gradient(to right, #00f, #00cfff) 1'
              }}
            />
            <figcaption className={`text-right whitespace-nowrap ${isMobile ? 'text-[20px] mt-0' : 'text-2xl mt-4'}`}>{eventDate}</figcaption>
          </div>
        </h2>
        <div className="text-left">
          <p className="mt-14 text-base leading-8 text-orange-200 max-md:mt-10 max-md:max-w-full">
            {eventDescC}
          </p>
          <p className="mt-5 text-base font-light leading-8 text-orange-200 max-md:max-w-full">
            {eventDescE}
          </p>
        </div>
        {/*  <div className="flex flex-col self-end mt-8 text-base">
          <span>店长: {manager}</span>
        </div> */}
      </div>
    </article>
  );
};
const EventList: React.FC<{ id?: string }> = ({ id }) => {
  const [events, setEvents] = useState<EventProps[]>([]);
  const [isMobile, setIsMobile] = useState(false);

  const loadData = useCallback(async () => {
    const excludedIds = [0];
    const limit = 2;
    try {
      const res = await getEventsCoverByRandom({ limit, excludedIds });
      if (res && res.data) {
        const eventList = res.data.map((event: any) => ({
          //date: event.date || '', // 这里需要根据你的实际数据结构进行调整
          title: event.title,
          imgSrc: event.cover,
          altText: "Event image",
          eventDate: event.subtitle || '', // 这里需要根据你的实际数据结构进行调整
          eventDescC: event.description,
          eventDescE: event.en_description,
          //manager: "Krueger Smith", // 假设这个字段是固定的
          //postedDate: event.date || '' // 这里需要根据你的实际数据结构进行调整
        }));
        setEvents(eventList);
      }
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  return (
    <div id={id} className="flex flex-col items-center self-stretch px-20 py-20 mt-12 w-full bg-stone-950 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <header className="text-xl font-medium text-center text-orange-800">
        最新情報
      </header>
      <h1 className="mt-6 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
        News
      </h1>
      <main className="self-stretch mt-0 mr-3 ml-3 max-md:mr-2.5 max-md:max-w-full">
        {events.map((event, index) => (
          <div className="self-stretch mt-0 mr-3 ml-3 max-md:mr-2.5 max-md:max-w-full" key={index}>
            <Event {...event} />
          </div>
        ))}
      </main>
      <nav className="justify-center items-center px-16 py-0 mt-28 mb-10 max-w-full text-xl font-bold text-white whitespace-nowrap border-fuchsia-700 border-solid border-[3px] leading-[40px] rounded-[51.2px] w-[230px] max-md:px-5 max-md:mt-10">
        <a href="#" onClick={() => { window.location.replace('/event') }} className="block w-full h-full">More</a>
      </nav>
    </div>
  );
};

export default EventList;
/* import { useState, useEffect, useCallback } from "react";
import { getEventsCoverByRandom } from '../api';
//import { useNavigate } from "react-router-dom";


type EventProps = {
  date: string;
  title: string;
  imgSrc: string;
  altText: string;
  eventDate: string;
  eventDescC: string;
  eventDescE: string;
  manager: string;
  postedDate: string;
};

const Event: React.FC<EventProps> = ({
  date,
  title,
  imgSrc,
  altText,
  eventDate,
  eventDescC,
  eventDescE,
  manager,
  postedDate,
}) => (
  <article className="flex gap-5 max-md:flex-col max-md:gap-0">
    <figure className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <img
        loading="lazy"
        src={imgSrc}
        alt={altText}
        className="grow w-full aspect-[1.22] max-md:mt-10 max-md:max-w-full"
      />
    </figure>
    <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
      <h2 className="flex flex-col mt-7 text-4xl font-medium text-orange-100 leading-[56px] max-md:mt-10 max-md:max-w-full">
        <span className="max-md:max-w-full">{title}</span>
        <div className="flex flex-col self-end mt-5 mr-7 max-w-full text-neutral-400 w-[389px] max-md:mr-2.5">
          <img
            loading="lazy"
            src={imgSrc}
            alt={altText}
            className="self-end max-w-full border-fuchsia-600 border-solid aspect-[100] border-[3px] stroke-[3.306px] stroke-fuchsia-600 w-[283px]"
          />
          <figcaption className="mt-6">{eventDate}</figcaption>
        </div>
      </h2>
      <div className="text-left">
        <p className="mt-14 text-base leading-8 text-orange-200 max-md:mt-10 max-md:max-w-full">
          {eventDescC}
        </p>
        <p className="mt-5 text-base font-light leading-8 text-orange-200 max-md:max-w-full">
          {eventDescE}
        </p>
      </div>
      <div className="flex flex-col self-end mt-8 text-base">
        <span>店长: {manager}</span>
        <time className="self-end mt-2.5">{postedDate}</time>
      </div>
    </div>
  </article>
);

const EventList: React.FC<{ id?: string }> = ({ id }) => {
  const [events, setEvents] = useState<EventProps[]>([]);

  const loadData = useCallback(async () => {
    const excludedIds = [0];
    const limit = 2;
    try {
      const res = await getEventsCoverByRandom({ limit, excludedIds });
      if (res && res.data) {
        const eventList = res.data.map((event: any) => ({
          date: event.date || '', // 这里需要根据你的实际数据结构进行调整
          title: event.title,
          imgSrc: event.cover,
          altText: "Event image",
          eventDate: event.date || '', // 这里需要根据你的实际数据结构进行调整
          eventDescC: event.description,
          eventDescE: event.en_description,
          manager: "Krueger Smith", // 假设这个字段是固定的
          postedDate: event.date || '' // 这里需要根据你的实际数据结构进行调整
        }));
        setEvents(eventList);
      }
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div id={id} className="flex flex-col items-center self-stretch px-20 py-20 mt-32 w-full bg-stone-950 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <header className="text-xl font-medium text-center text-orange-800">
        最新活动信息
      </header>
      <h1 className="mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
        Events News
      </h1>
      <main className="self-stretch mt-18 mr-3 ml-3 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
        {events.map((event, index) => (
          <div className="self-stretch mt-16 mr-3 ml-3 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full" key={index}>
            <Event {...event} />
          </div>
        ))}
      </main>
      <nav className="justify-center items-center px-16 py-0 mt-28 mb-10 max-w-full text-xl font-bold text-white whitespace-nowrap border-fuchsia-700 border-solid border-[3px] leading-[40px] rounded-[51.2px] w-[230px] max-md:px-5 max-md:mt-10">
        <a href="#" onClick={() => { window.location.replace('/event') }} className="block w-full h-full">More</a>
      </nav>
    </div>
  );
};

export default EventList; */

/* import * as React from "react";

type EventProps = {
  date: string;
  title: string;
  imgSrc: string;
  altText: string;
  eventDate: string;
  eventDescC: string;
  eventDescE: string;
  manager: string;
  postedDate: string;
};

const Event: React.FC<EventProps> = ({
  date,
  title,
  imgSrc,
  altText,
  eventDate,
  eventDescC,
  eventDescE,
  manager,
  postedDate,
}) => (
  <article className="flex gap-5 max-md:flex-col max-md:gap-0">
    <figure className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <img
        loading="lazy"
        src={imgSrc}
        alt={altText}
        className="grow w-full aspect-[1.22] max-md:mt-10 max-md:max-w-full"
      />
    </figure>
    <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
      <h2
        className="flex flex-col mt-7 text-4xl font-medium text-orange-100 leading-[56px] max-md:mt-10 max-md:max-w-full"
      >
        <span className="max-md:max-w-full">{title}</span>
        <div className="flex flex-col self-end mt-5 mr-7 max-w-full text-neutral-400 w-[389px] max-md:mr-2.5">
          <img
            loading="lazy"
            src={imgSrc}
            alt={altText}
            className="self-end max-w-full border-fuchsia-600 border-solid aspect-[100] border-[3px] stroke-[3.306px] stroke-fuchsia-600 w-[283px]"
          />
          <figcaption className="mt-6">{eventDate}</figcaption>
        </div>
      </h2>
      <div className="text-left">
      <p className="mt-14 text-base leading-8 text-orange-200 max-md:mt-10 max-md:max-w-full">
        {eventDescC}
      </p>
      <p className="mt-5 text-base font-light leading-8 text-orange-200 max-md:max-w-full">
        {eventDescE}
      </p></div>
      <div className="flex flex-col self-end mt-8 text-base">
        <span>店长: {manager}</span>
        <time className="self-end mt-2.5">{postedDate}</time>
      </div>
    </div>
  </article>
);

const EventList: React.FC<{ id?: string }> = ({ id }) => {
  const events = [
    {
      date: "2024.5.5",
      title: "2024.5.5 星光夜宴",
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/event1.png",
      altText: "Event image",
      eventDate: "5.5 Star Night Event",
      eventDescC:
        "在这个特别的夜晚，我们的酒吧将迎来一位神秘的明星嘉宾，为大家带来一场难忘的星光夜宴。不要错过这次与明星近距离接触的机会，赶快预约你的座位吧！",
      eventDescE:
        "On this special night, our bar will welcome a mysterious celebrity guest for an unforgettable starry night feast. Don't miss this opportunity to get up close and personal with the star, book your seat now!",
      manager: "Krueger Smith",
      postedDate: "2024.4.22",
    },
    {
      date: "2024.5.6",
      title: "2024.5.6 丰韵之夜",
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/event2.png",
      altText: "Event image",
      eventDate: "5.6 Sexy Night Event",
      eventDescC:
        "我们将于2024年5月5日邀请xx来我们的银座紫峰SHIHOO高级俱乐部,活动日期开始:2024年5月5日晚上19:00,活动时长3小时,请不要错过!",
      eventDescE:
        "We will invite xx to our club on May 5, 2024, the date of the event starts: May 5, 2024 at 19:00 pm, the event is 3 hours long, please do not miss it!",
      manager: "Krueger Smith",
      postedDate: "2024.4.22",
    },
  ];

  return (
    <div id={id} className="flex flex-col items-center self-stretch px-20 py-20 mt-32 w-full bg-stone-950 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <header className="text-xl font-medium text-center text-orange-800">
        最新活动信息
      </header>
      <h1
        className="mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl"
      >
        Events News
      </h1>
      <main className="self-stretch mt-18 mr-3 ml-3 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
        {events.map((event, index) => (
          <div className="self-stretch mt-16 mr-3 ml-3 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full" key={index}>
            <Event {...event} />
          </div>
        ))}
      </main>
      <nav className="justify-center items-center px-16 py-0 mt-28 mb-10 max-w-full text-xl font-bold text-white whitespace-nowrap border-fuchsia-700 border-solid border-[3px] leading-[40px] rounded-[51.2px] w-[230px] max-md:px-5 max-md:mt-10">
        <a href="#" onClick={() => { window.location.replace('/event') }} className="block w-full h-full">More</a>
      </nav>
    </div>
  );
};

export default EventList; */