import Header from "../../component/Header";
import { terms1 } from "./text";
function About() {
  return (
    <div className="bg-[#181818]">
      <Header />
      <div className="flex  flex-col justify-center items-center mb-24">
        <p className="text-2xl md:text-5xl font-montserrat-extra-bold text-[#FF548B] w-[80%] md:w-[400px]">MEMBERSHIP</p>
        <p className="text-2xl mt-4 md:text-5xl text-left w-[80%] md:w-[800px]"> Membership Rules</p>
        <p style={{ lineHeight: '34px' }} className="text-left w-[80%] md:w-[800px]">
          This website has a comprehensive membership system<br />

          Membership rules apply to all users who join this platform, joining implies that you agree to and abide by the following rules.<br />

          Age Limit<br />

          Users must be at least 18 years old to join the membership, if found under the age of 18 years old users fraudulently use the identity of others to join the membership, the platform will immediately terminate their membership.<br />

          1.Subscription <br />

          Subscription is a privilege obtained by payment of a certain fee. The Platform will provide a monthly subscription system for Members, and the specific content and fee will be clearly indicated on the Platform.<br />

          1.2 The subscription fees are determined by the Platform and may be adjusted according to market demand. Members will be notified in advance of any adjustments to the subscription fee, and it is up to the Member to decide whether or not to continue subscribing.<br />

          2. Visibility of internal information<br />

          After becoming a fully paid member, you will be given the right to access all the information within the platform, including contact us, etc. Internal information includes but is not limited to other members' personal information, contact information, interaction records, etc. Members should comply with applicable laws and regulations when using such information, and shall not use the information for illegal, infringing or abusive behavior. Members should comply with applicable laws and regulations when using such information, and shall not use the information for illegal, infringing or abusive behavior. The platform reserves the right to adjust the visibility of internal information at any time according to laws and regulations and operational needs, and all adjustments will be notified to members in advance.<br />

          3. Member Responsibilities and Obligations<br />

          Members should keep their account information in a safe place and should not share their account information with others. Members shall bear all the consequences caused by the leakage of account information. Member's behavior on the platform should be in accordance with laws and regulations, and shall not engage in illegal, false propaganda, infringement of rights and other activities. Members shall not interfere with the normal operation of the platform in any way, including but not limited to malware, network attacks and other behavior. Members should respect the rights and interests of other members, and shall not obtain internal information for harassment, invasion of privacy and other behaviors.<br />

          4. Termination of Membership<br />

          The Platform reserves the right to terminate membership at any time based on the actual circumstances, including but not limited to violation of the membership rules, abuse of the Platform's privileges, illegal behavior, etc. The Platform reserves the right to terminate membership at any time based on the actual circumstances. If a member has unused subscriptions after termination, the platform will not refund the corresponding subscription fees.<br />

          5. Disclaimer<br />

          Members should judge the truthfulness and legality of the information on the platform, and the platform is not responsible for any loss or dispute arising from the use of such information. The platform is not responsible for any loss or dispute arising from the use of such information. The platform is not responsible for any service interruption or data loss due to force majeure, technical failures and other reasons. Please read and understand the above membership rules before joining. If you agree and are willing to comply with these rules, please continue to complete the membership registration and payment process. If you have any questions or comments, please feel free to contact us. The platform reserves the right to adjust the membership rules, the adjusted rules will be announced and notified to members in a timely manner.<br />

          <p className="text-2xl mt-4 md:text-5xl text-left w-[80%] md:w-[800px]">会员规则</p>
        </p>
        {/* <pre className="text-sm md:text-xl text-left w-[80%] md:w-[800px] my-8">{terms1}</pre> */}
        <p className="text-sm mt-4 md:text-xl text-left w-[80%] md:w-[800px]">本網站全面會員制度</p>
        <p className="text-sm md:text-xl text-left w-[80%] md:w-[800px]">會員規則適用於所有加入本平台的用户, 加入會員意味着您同意並遵守以下規定.</p>

        <p className="text-sm mt-4 md:text-xl text-left w-[80%] md:w-[800px]">年齡限制</p>
        <p className="text-sm md:text-xl text-left w-[80%] md:w-[800px]">加入會員的用户必須年滿18週歲,
          如發現未滿18週歲用户冒用他人身份加入會員,
          平台將立即終止其會員資格.</p>

        <p className="text-sm mt-4 md:text-xl text-left w-[80%] md:w-[800px]">1.訂閲制度 </p>
        <p className="text-sm md:text-xl text-left w-[80%] md:w-[800px]">會員訂閲是通過支付一定費用獲得的特權,
          平台將提供月制度會員訂閲類型,
          具體內容和費用將在平台上明確標示.</p>
        <p className="text-sm md:text-xl text-left w-[80%] md:w-[800px]">
          1.2 訂閲費用由平台決定,可能會根據市場需求進行調整.
          任何費用調整將提前通知會員,會員可自行決定是否繼續訂閲.</p>

        <p className="text-sm mt-4 md:text-xl text-left w-[80%] md:w-[800px]">2. 內部信息可見性 </p>
        <p className="text-sm md:text-xl text-left w-[80%] md:w-[800px]">成為完全支付會員後,您將獲得訪問平台內部所有人信息的權限,包括聯繫我們等
          內部信息包括但不限於其他會員的個人資料、聯繫方式、互動記錄等.
          會員在使用這些信息時應遵守適用法律法規,不得將信息用於非法,侵權或濫用等行為.
          平台保留根據法律法規和經營需要隨時調整內部信息可見性的權利,所有調整將提前通知會員.</p>

        <p className="text-sm mt-4 md:text-xl text-left w-[80%] md:w-[800px]">3. 會員責任與義務</p>
        <p className="text-sm md:text-xl text-left w-[80%] md:w-[800px]">會員應妥善保管個人賬户信息,不得與他人分享賬户信息.因賬户信息泄露造成的一切後果由會員承擔.
          會員在平台上的行為應符合法律法規,不得從事違法、虛假宣傳、侵權等活動.
          會員不得以任何方式干擾平台正常運營,包括但不限於惡意軟件、網絡攻擊等行為.
          會員應尊重其他會員的權益,不得通過獲取內部信息進行騷擾,侵犯隱私等行為.
        </p>

        <p className="text-sm mt-4 md:text-xl text-left w-[80%] md:w-[800px]">4. 終止會員資格 </p>
        <p className="text-sm md:text-xl text-left w-[80%] md:w-[800px]">平台有權基於實際情況隨時終止會員資格,包括但不限於違反會員規則、濫用平台特權、違法行為等.
          若會員在終止資格後仍有未使用完的訂閲,平台將不與退還相應訂閲費用.
        </p>

        <p className="text-sm mt-4 md:text-xl text-left w-[80%] md:w-[800px]">5. 免責條款 </p>
        <p style={{ marginBottom: 100 }} className="text-sm md:text-xl text-left w-[80%] md:w-[800px]">會員在使用平台內部信息時應自行判斷信息真實性和合法性,平台不對會員因使用這些信息而產生的任何損失或糾紛負責.
          平台不對由於不可抗力,技術故障等原因導致的服務中斷或數據丟失承擔責任.
          請您在加入會員之前仔細閲讀並理解以上會員規則.
          如果您同意並願意遵守這些規定,請繼續完成會員註冊和支付流程.
          如有疑問或意見,請隨時與我們聯繫.
          平台保留對會員規則進行調整的權利,調整後的規則將在公示並及時通知會員.</p>

      </div>
    </div>
  );
}

export default About;
