import { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
interface CustomInputFieldProps {
  name: string;
  type: string;
  placeholder: string;
}

const CustomInputField: React.FC<CustomInputFieldProps> = ({ name, type, placeholder }) => {
  const [showPassword, setShowPassword] = useState(true);

  const handleTogglePassword = () => {
    setShowPassword(prevState => !prevState);
  };

  const passwordIconColor = showPassword ? 'text-[#494a4a]' : 'text-[#ff548b]';

  return (
    <div className="relative">
      <Field
        // auto
        className="custom-input border-b-2 md:border-b-4 border-[#494a4a] focus:border-white bg-transparent w-full py-2.5 outline-none md-2 md:mb-4"
        name={name}
        type={showPassword ? type : 'text'}
        placeholder={placeholder}
      />
      {type === 'password' ? <span
        className={`w-4 h-4 password-toggle-icon absolute right-4 top-0 cursor-pointer ${passwordIconColor}`}
        onClick={handleTogglePassword}
      >
        {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
      </span> : null}
      <div className='h-8 mb-2'>
        <ErrorMessage style={{ color: 'red', textAlign: 'left' }} name={name} component="div" />
      </div>
    </div>
  );
};

export default CustomInputField;


