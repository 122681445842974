// src/components/DataTable.tsx
import React from 'react';
import { Table, Space, Button, Image, Popover } from 'antd';
import { useNavigate } from 'react-router-dom';
interface User {
  id: string;
  username: string;
  description: string;
  avatar: string;
  banner: string;
}

interface DataTableProps {
  data: User[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

const DataTable: React.FC<DataTableProps> = ({ data, onDelete, onEdit }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: User) => (
        <Space size="middle">
          {/* <Button onClick={() => onEdit(record.id)}>Edit</Button> */}
          <Button onClick={() => onDelete(record.id)} danger>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: 'max-content' }}
      pagination={{ 
        pageSizeOptions: ['10', '25', '50'],
        showSizeChanger: true,
      }} 
    />
  );
};

export default DataTable;
