import useModalStore from "../modalStore";

// 获取指定名称的Cookie值，返回值类型为string或null
export function getCookie(name: string): string | null {
  const cookieStr = document.cookie;
  const cookies = cookieStr.split('; ');

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return cookieValue;
    }
  }

  return null;
}

// 将数据存储到localStorage中
export function setLocalStorage(key: string, value: any): void {
  localStorage.setItem(key, value);
}

// 从localStorage中获取数据
export function getLocalStorage(key: string) {
  try {
    const data = localStorage.getItem(key);
    return data;
  } catch (error) {
    console.log('getLocalStorage', error);
    return '';
  }
}


// 函数节流的实现
export function throttle(func: () => void, delay: number) {
  let timer: NodeJS.Timeout | null = null;

  return function () {
    if (!timer) {
      timer = setTimeout(() => {
        func();
        timer = null;
      }, delay);
    }
  };
}

export function contact() {
  const { showModal } = useModalStore.getState();
  showModal()
}