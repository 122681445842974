//import * as React, { useState, useEffect } from "react";
import { useEffect, useState } from 'react';

const Information: React.FC<{ id?: string }> = ({ id }) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <footer id={id} className="relative bg-black text-white py-10">
      <div className="flex flex-col items-center justify-center px-5">
        {/* <div className={`relative w-full ${isMobile ? 'h-auto' : 'h-96'} mb-10 mt-20`}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.9354100571215!2d139.76348331623163!3d35.669743980198215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bf00fcd8b7b%3A0x839a7a9bcb19b7ed!2s8-ch%C5%8Dme-5-12%20Ginza%2C%20Ch%C5%AB%C5%8D%20City%2C%20Tokyo%20104-0061%2C%20Japan!5e0!3m2!1sen!2sus!4v1621537636674!5m2!1sen!2sus"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            className={`${isMobile ? 'h-[300px] w-full' : 'absolute top-40 left-0 w-full h-full'}`}
          ></iframe>
          {isMobile ? (
            <div className="flex flex-col items-center text-center mt-20">
              <article className="flex flex-col items-center px-16 py-12 text-center border border-orange-200 border-solid bg-stone-950 w-80 h-[300px] mb-5">
                <img
                  loading="lazy"
                  src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/location_icon.png"
                  alt=""
                  className="w-7 h-auto"
                />
                <h2 className="mt-8 text-2xl font-medium leading-9 text-orange-100">
                  Address
                </h2>
                <p className="self-stretch mt-7 text-xs leading-6 text-orange-200">
                  Uruwashi II, 3f 8-chōme-5-12 Ginza, Chuo City, Tokyo
                </p>
              </article>
              <article className="flex flex-col items-center px-16 py-12 text-center border border-orange-200 border-solid bg-stone-950 w-80 h-[300px]">
                <img
                  loading="lazy"
                  src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/time_icon.png"
                  alt=""
                  className="w-7 h-auto"
                />
                <h2 className="mt-8 text-2xl font-medium leading-9 text-orange-100">
                  Working Hours
                </h2>
                <p className="self-stretch mt-7 text-xs leading-6 text-orange-200">
                Mon - Fri : 19:00 - 24:00<br />
                Sat - Sun : closed（需要預約）
                </p>
              </article>
            </div>
          ) : (
            <div className="absolute top-0 left-0 w-full h-full flex flex-col md:flex-row justify-around items-center text-center gap-0">
              <article className="flex flex-col items-center px-16 py-12 text-center border border-orange-200 border-solid bg-stone-950 w-80 h-[300px]">
                <img
                  loading="lazy"
                  src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/location_icon.png"
                  alt=""
                  className="w-7 h-auto"
                />
                <h2 className="mt-8 text-2xl font-medium leading-9 text-orange-100">
                  Address
                </h2>
                <p className="self-stretch mt-7 text-xs leading-6 text-orange-200">
                  WAKO BLD., 3F, 8-chōme-3-11 Ginza, Chuo City, Tokyo 104-0061
                </p>
              </article>
              <article className="flex flex-col items-center px-16 py-12 text-center border border-orange-200 border-solid bg-stone-950 w-80 h-[300px]">
                <img
                  loading="lazy"
                  src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/time_icon.png"
                  alt=""
                  className="w-7 h-auto"
                />
                <h2 className="mt-8 text-2xl font-medium leading-9 text-orange-100">
                  Working Hours
                </h2>
                <p className="self-stretch mt-7 text-xs leading-6 text-orange-200">
                  Mon - Fri : 19:00 - 24:00<br />
                  Sat - Sun : closed（需要預約）
                </p>
              </article>
            </div>
          )}
        </div> */}
        <div className="flex flex-col items-center justify-center relative">
          {/* <div className="relative top-[180px] text-center"> */}
          <div className={`relative text-center ${isMobile ? 'mt-0' : 'top-[180px]'}`}>
            <p className= {`text-lg ${isMobile ? 'mb-0' : 'mb-40'}`}>
              We are preparing...
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Information;











/* import * as React from "react";
const Information: React.FC <{ id?: string }> = ({ id }) =>  {
  return (
    <footer id={id} className="relative bg-black text-white py-10 ">
      <div className="flex flex-col items-center justify-center px-5">
        <div className="relative w-full h-96 mb-10 mt-20">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.9354100571215!2d139.76348331623163!3d35.669743980198215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bf00fcd8b7b%3A0x839a7a9bcb19b7ed!2s8-ch%C5%8Dme-5-12%20Ginza%2C%20Ch%C5%AB%C5%8D%20City%2C%20Tokyo%20104-0061%2C%20Japan!5e0!3m2!1sen!2sus!4v1621537636674!5m2!1sen!2sus"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            className="absolute top-40 left-0"
          ></iframe>
        <div className="absolute top-0 left-0 w-full h-full flex flex-col md:flex-row justify-around items-center text-center gap-0">
          <article className="flex flex-col items-center px-16 py-12 text-center border border-orange-200 border-solid bg-stone-950 max-w-[342px] mr-[-200px]">
            <img
            loading="lazy"
            src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/location_icon.png"
            alt=""
            className="w-7 h-auto"
            />
            <h2 className="mt-8 text-2xl font-medium leading-9 text-orange-100">
              Address
              </h2>
              <p className="self-stretch mt-7 text-xs leading-6 text-orange-200">
                Uruwashi II, 3f 8-chōme-5-12 Ginza, Chuo City, Tokyo
              </p>
          </article>
          <article className="flex flex-col items-center px-16 py-12 text-center border border-orange-200 border-solid bg-stone-950 max-w-[342px] ml-[-200px]">
            <img
            loading="lazy"
            src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/time_icon.png"
            alt=""
            className="w-9 h-auto"
            />
            <h2 className="mt-8 text-2xl font-medium leading-9 text-orange-100">
              Working Houes
            </h2>
            <p className="self-stretch mt-7 text-xs leading-6 text-orange-200">
              Mon - Fri : 19:00 - 26:00<br/>
              Sat - Sun : 18:00 - 27:00
            </p>
          </article>
        </div>
      </div>
        <div className="flex flex-col items-center justify-center relative">
  <div className="relative top-[180px] text-center">
    <img
      src='https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/vector.png'
      className='mx-auto mt-36 z-10'
      alt="Vector"
    />
    <p className="text-lg mt-4 mb-4 self-stretch mt-14 w-full text-2xl leading-10 text-center text-orange-200 max-md:mt-10 max-md:max-w-full">Luxurious and beautiful, Japan is all yours at the Shihoo Shikamime Club in Ginza.</p>
    <p className="text-lg mb-40">奢华之境,佳人相伴,醉心日本,一切尽在银座Shihoo 紫峰高级俱乐部</p>
  </div>
</div>

      </div>
    </footer>
  );
};

export default Information;
 */