import React from 'react';
import { Table, Space, Button, Image, Popover } from 'antd';
import { useNavigate } from 'react-router-dom';
interface User {
  id: string;
  username: string;
  description: string;
  avatar: string;
  banner: string;
}

interface DataTableProps {
  data: User[];
}

const DataTable: React.FC<DataTableProps> = ({ data }) => {
  const navigate = useNavigate()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '支付订单号（成功才有）',
      dataIndex: 'saleId',
      key: 'saleId',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: number) => {
        if (text === 2) {
          return '已支付'
        }
        if (text === 1) {
          return '待支付'
        }
        if (text === 0) {
          return '支付错误'
        }
        return '未知'
      },
    },
    {
      title: 'User id',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: '支付时间',
      dataIndex: 'v-id',
      render: (text: string) => {
        if (!text) {
          return ''
        }
        return new Date(Number(text))?.toLocaleString()
      },
      key: 'v-id',
    },
    {
      title: '上次过期时间',
      dataIndex: 'last-expire',
      render: (text: string) => {
        if (!text) {
          return ''
        }
        return new Date(Number(text))?.toLocaleString()
      },
      key: 'last-expire',
    },
    {
      title: '下次过期时间',
      dataIndex: 'new-expire',
      render: (text: string) => {
        if (!text) {
          return ''
        }
        return new Date(Number(text))?.toLocaleString()
      },
      key: 'new-expire',
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      render: (text: string) => {
        if (!text) {
          return ''
        }
        return new Date(Number(text))?.toLocaleString()
      },
      key: 'updateTime',
    },
    {
      title: '用户支付信息',
      dataIndex: 'userInfo',
      key: 'userInfo',
    },

  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: 'max-content' }}
      pagination={{ 
        pageSizeOptions: ['10', '25', '50'],
        showSizeChanger: true,
      }} 
    />
  );
};

export default DataTable;
