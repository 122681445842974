// src/MyForm.tsx
import React, { useState } from 'react';
import { Form, Input, Button, Upload, message, Spin } from 'antd';
import ImageUpload from '../../../../component/ImageUpload';
import ImageUploadList from '../../../../component/ImageUploadList';
import VideoUpload from '../../../../component/VideoUpload';
import { addAnimalArtwork } from '../../../../api';

interface Props {
  mode: 'edit' | 'create-image' | 'create-video';
  initialData?: {
    username: string;
    description: string;
    banner: string;
    avatar: string;
  };
  params:any
  animal:any
  onSave: (data: any) => void;
  loadData:()=>void
  setIsVisible: (boolean: any) => void;
  isLoading?:boolean
}

const MyForm: React.FC<Props> = ({ mode, initialData, onSave,setIsVisible ,params,animal,loadData}) => {
  const [form] = Form.useForm();
  const [loading,setLoading] =useState(false);
  const onFinish = async(values: any) => {
    setLoading(true)
    if (mode === 'create-image') {
      const extradata = {
        artistsId: params?.animalId,
        artistsName: animal?.username || '',
        tags: animal?.tags || '',
        mediaType: params?.videoUrl ? 'video' : 'image',
        likes: 0,
      }
      await Promise.all( values?.imageUrlList?.map( async(value: string) => ( await addAnimalArtwork({ imageUrl: value, ...extradata }))))
     setIsVisible(false)
     loadData()
  } else {
    setLoading(true)
    await onSave(values);
    setIsVisible(false)
      setLoading(false)
    }
  };
  const handleImageUploadSuccess = (url: string, fieldName: string) => {
    form.setFieldsValue({
      [fieldName]: url
    });
  };
  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        initialValues={mode === 'edit' ? initialData : {}}
        onFinish={onFinish}
      >
        {mode === 'create-image' ? <Form.Item name="imageUrlList" label="Image List">
          <ImageUploadList onUploadSuccess={(urls) => {
            form.setFieldsValue({
              'imageUrlList': urls
            });
          }} />
        </Form.Item> : null}
        {mode === 'create-video' ? <Form.Item name="imageUrl" label="Image Url" rules={[{ required: true, message: 'Please upload an image!' }]}>
          <ImageUpload onUploadSuccess={(url) => handleImageUploadSuccess(url, 'imageUrl')} />
        </Form.Item> : null}
        {mode === 'create-video' ? <Form.Item name="videoUrl" label="Video Url">
          <VideoUpload onUploadSuccess={(url) => handleImageUploadSuccess(url, 'videoUrl')} />
        </Form.Item> : null}
        {/* <Form.Item name="avatar" label="Avatar">
          <ImageUploadList onUploadSuccess={(url) => {
            console.log(url);

          }} />
        </Form.Item> */}
        <Form.Item>
          <Button type="primary" htmlType="submit" className='text-black'>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default MyForm;
