import { create } from 'zustand'

// 定义状态的类型
type User = {
  role: string, // 用户角色是 Admin
  uid?: number,
};

type AuthState = {
  user: User | null;
  isLoggedIn: boolean;
  login: (userInfo: User) => void;
  logout: () => void;
};

const useAuthStore = create<AuthState>((set) => ({
  user: {
    role: 'guest',
  },
  isLoggedIn: false,
  login: (userInfo: User) => {
    set({
      user: userInfo,
      isLoggedIn: true
    });
  },
  logout: () => {
    set({
      user: { role: 'guest' },
      isLoggedIn: false
    });
  }
}));

export default useAuthStore;
