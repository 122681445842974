import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import useAuthStore from '../authStore';
import { useNavigate } from 'react-router-dom';

const UserProfileIcon: React.FC = () => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { logout, user } = useAuthStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    // 在这里执行登出逻辑，包括移除本地存储中的 aToken
    localStorage.removeItem('aToken');
    logout();
    setPopoverVisible(false);
    navigate('/');
  };

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  if (!user?.uid) {
    return null;
  }

  const content = (
    <div className="p-4">
      <Button type="primary" danger block onClick={handleLogout}>
        Log Out
      </Button>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      open={popoverVisible}
      onOpenChange={handlePopoverVisibleChange}
    >
      <div className="cursor-pointer flex">
        <LogoutOutlined className="hidden md:block" style={{ color: 'yellow' }} />
      </div>
    </Popover>
  );
};

export default UserProfileIcon;
