import React, { useRef, useState, ChangeEvent, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getLocalStorage, setLocalStorage } from '../../grocery/utils';
import Header from '../../component/Header';
import BackGround from '../../component/BackGround';
import { updateUser } from '../../api';
import useAuthStore from '../../authStore';
import CustomInputField from '../../component/CustomInputField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { message } from 'antd';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .matches(/^(?=.*[a-zA-Z])(?=.*\d)/, 'Password must contain at least one letter and one number')
    .min(8, 'Password must be at least 8 characters long'),
});

const MyComponent: React.FC = () => {
  const { user, isLoggedIn, login, logout } = useAuthStore();
  const navigate = useNavigate();
  if (!isLoggedIn) {
    return <div>No permission</div>
  }
  return <div >
    <Header />
    <BackGround />
    <div className='flex flex-col justify-center items-center w-full mt-8'>
      <Formik
        initialValues={{
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values: any, { setSubmitting }) => {
          setSubmitting(false);
          const res = await updateUser(values)
          if (res.data === 'ok') {
            message.success('Password has been reset.')
            navigate('/')
            return
          }
          message.error('Password reset error.')
        }}
      >
        {() => (
          <Form className='w-[80%] md:w-[400px] '>
            <CustomInputField name="password" type="password" placeholder="New Password" />
            <button
              type='submit'
              className="my-4 bg-gradient-to-r from-[#F093FB] to-[#F5576C] text-white font-montserrat flex items-center justify-center py-2 px-4 rounded-full w-full"
            >
              Reset password
            </button>
          </Form>
        )}
      </Formik>
    </div>
  </div>;
};

export default MyComponent;
