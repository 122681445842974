import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../component/Header';
import TypingEffect from '../component/TypingEffect';
import ImageFadeIn from '../component/ImageFadeIn';
import BackGround from '../component/BackGround';
import useAuthStore from '../authStore';
import Footer from '../component/Footer';
import Information from '../component/Information';
import EventList from '../component/EventList';
import JobInformation from '../component/JobInformation';
import Banner from '../component/Banner';
import CastWall from '../component/CastWall';
import AquaticAnimalsComponent from '../component/ AquaticAnimalsComponent';

const DescriptionSection: React.FC<{ heading: string; subheading: React.ReactNode; text: React.ReactNode; descridis: string }> = ({
  heading,
  subheading,
  text,
  descridis
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className={`flex flex-col px-5 max-md:mt-10 max-md:max-w-full ${descridis}`}>
      <h2 className={`text-xl text-left font-medium text-orange-800 max-md:max-w-full ${isMobile ? 'text-lg' : ''}`}>{heading}</h2>
      <h3 className={`mt-5 text-6xl text-left text-orange-100 uppercase max-md:max-w-full ${isMobile ? 'text-lg' : ''}`}>{subheading}</h3>
      <div className={`mt-14 text-sm leading-6 text-pink-400 max-md:mt-10 max-md:max-w-full text-left ${isMobile ? 'text-xs leading-5' : ''}`}>
        {text}
      </div>
    </section>
  );
};

function Home() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthStore();
  const [step, setStep] = useState<number>(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setStep((prevStep) => (prevStep + 1) % 6);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleReservationClick = () => {
    window.location.href = "https://forms.gle/9uZrBhn4s3CCek9W9";
  };

  return (
    <div className="Home max-w-[1440px] w-full mx-auto">
      <Header />
      <BackGround />

      {/*   <Banner
        videoSrcMobile="https://tier1.sgp1.cdn.digitaloceanspaces.com/static/forhomepage.MOV"
        videoSrcDesktop="https://tier1.sgp1.cdn.digitaloceanspaces.com/static/forhomepage.MOV"
        altText="SHIRHOO"
        heading={
          <span>
            AquaShop Tier1
          </span>
        }
        className="font-sans"
        overlayImgSrc="https://tier1.sgp1.cdn.digitaloceanspaces.com/static/homeword.png"
        overlayClassName="flex absolute z-10"
        headingClassName="flex absolute lg:text-8xl sm:text-xl whitespace-nowrap"
        videoClassName="flex absolute inset-0 w-full h-full object-contain"
        addtionalImgSrc="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/blackshaow.png"
        overlayPositionMobile={{ top: '30%', left: '50%', transform: 'translate(-50%, -50%)', width: '10rem', height: 'auto' }} // 自定义手机端图片位置
        overlayPositionDesktop={{ top: '35%', left: '50%', transform: 'translate(-50%, -50%)', width: '25rem', height: 'auto' }} // 自定义桌面端图片位置
        headingPositionMobile={{ top: '38%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '1rem' }} // 自定义手机端heading位置
        headingPositionDesktop={{ top: '43%', left: '50%', transform: 'translate(-50%, -50%)' }} // 自定义桌面端heading位置
        buttonPositionMobile={{ top: '44%', left: '50%', transform: 'translate(-50%, 0)', padding: '0.5rem 1rem' }} 
        buttonPositionDesktop={{ top: '50%', left: '50%', transform: 'translate(-50%, 0)' }}
        arrowPositionMobile={{ top: '80%', left: '47%', transform: 'translate(-50%, -50%)' }} // 自定义箭头位置
        showArrow={false} // 控制箭头显示
        maintainAspectRatio={false}
        button={
          <div className="flex gap-5 justify-between py-3 pr-5 pl-5 backdrop-blur-[7.40625px] rounded-[40px_40px_40px_0px] max-w-md mx-auto max-md:px-3" onClick={handleReservationClick}>
            <div className="flex flex-col text-white whitespace-nowrap">
              <span className="text-md leading-[24px] mr-12 ml-12">Reservation</span>
              <h3 className="mt-1 text-md text-center leading-[24px] mr-12 ml-12">即刻預定</h3>
            </div>
            <div className="flex flex-col justify-center items-start my-auto">
              <img
                loading="lazy"
                src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/arrow_icon.png"
                className="rounded-full w-[45px] h-auto"
                alt="Reservation icon"
              />
            </div>
          </div>
        }
      /> */}

      <div className='mt-36'>

        <div>お知らせ：店主のスキル不足て、ホームページにバグあります。</div>
        <div>魚の詳細を見る際、login求められるので、以下の閲覧用のアカウント使ってください</div>
        <div>メール:aquashoptier@test.com</div>
        <div>パス:aquashoptier1</div>
        <div>お手数ですが、バグを解決する前上記のアカウント使ってください。</div>
      </div>

      <AquaticAnimalsComponent />

      <div className="flex flex-col mt-12">
        <article id="aboutUs" className="mt-32 w-full max-md:mt-10 max-md:max-w-full">
          <section className={`flex gap-4 px-4 ${isMobile ? 'flex-col' : ''} max-md:gap-4 items-start`}>
            <figure className={`flex flex-col ${isMobile ? 'order-2 w-full' : 'w-1/2'} items-start`}>
              <img
                loading="lazy"
                src="https://tier1.sgp1.cdn.digitaloceanspaces.com/static/IMG_9665.JPG"
                alt="Image of the establishment"
                className="grow w-full object-contain max-md:max-w-full pl-2 pr-2"
              />
            </figure>
            <div className={`flex flex-col justify-start ${isMobile ? 'order-2 w-full' : 'w-1/2'}`}>
              <DescriptionSection
                heading="店について"
                subheading={<div className={` ${isMobile ? '' : 'text-[40px]'}`}>
                  AquaShop Tier1へようこそ！
                </div>}
                descridis="ml-0 mr-0"
                text={
                  <div className={`self-center text-left text-white text-left pl-2 pr-2 ${isMobile ? 'text-base leading-6 mt-0' : 'text-[15px] leading-9 mt-0'}`}>
                    <span className="text-white">珍カラが大好きで、自分がどうしても飼育したいカラシンを手に入れたくて始めたのがAquaShop Tier1です。こだわりは、独自の仕入れルートで、日本ではまだ見られない新種のカラシンを輸入すること。</span>
                    <span className="text-white">
                      <br />
                      <br />
                      今はブラジル産の珍カラに力を入れており、他では手に入らない特別な魚を見つけたい方は、ぜひAquaShop Tier1に問い合わせてください。
                      <br />
                    </span>
                    <span className="text-white">
                      ここでしか出会えない観賞魚たちと一緒に、皆さんのご利用をお待ちしています！
                    </span>
                  </div>
                }
              />
            </div>
          </section>
        </article>
      </div>
      <CastWall />
      <EventList id="eventsNews" />
      <JobInformation id="recruit" />
      <Information id="storeLocation" />
      <Footer />
    </div>
  );
}

export default Home;
