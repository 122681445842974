import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../component/Header';
import Footer from "../../component/Footer";
import EventWall from "../../component/EventWall";



const Event: React.FC = () => {

  return (
    <div className="bg-black max-w-[1440px] w-full mx-auto">
      <Header />
      <EventWall/>
      <Footer />
    </div>
  );
};

export default Event;
/* import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../component/Header';
import Footer from "../../component/Footer";

type EventProps = {
  date: string;
  title: string;
  imgSrc: string;
  altText: string;
  eventDate: string;
  eventDescC: string;
  eventDescE: string;
  manager: string;
  postedDate: string;
  onClick: () => void;
};

const EventItem: React.FC<EventProps> = ({
  date,
  title,
  imgSrc,
  altText,
  eventDate,
  eventDescC,
  eventDescE,
  manager,
  postedDate,
  onClick,
}) => (
  <article className="flex gap-5 max-md:flex-col max-md:gap-0 my-8 cursor-pointer" onClick={onClick}>
    <figure className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <img
        loading="lazy"
        src={imgSrc}
        alt={altText}
        className="grow w-full aspect-[1.22] max-md:mt-10 max-md:max-w-full"
      />
    </figure>
    <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
      <h2 className="flex flex-col mt-7 text-4xl font-medium text-orange-100 leading-[56px] max-md:mt-10 max-md:max-w-full">
        <span className="max-md:max-w-full">{title}</span>
        <div className="flex flex-col self-end mt-5 mr-7 max-w-full text-neutral-400 w-[389px] max-md:mr-2.5">
          <img
            loading="lazy"
            src={imgSrc}
            alt={altText}
            className="self-end max-w-full border-fuchsia-600 border-solid aspect-[100] border-[3px] stroke-[3.306px] stroke-fuchsia-600 w-[283px]"
          />
          <figcaption className="mt-6">{eventDate}</figcaption>
        </div>
      </h2>
      <div className="text-left">
        <p className="mt-14 text-base leading-8 text-orange-200 max-md:mt-10 max-md:max-w-full">
          {eventDescC}
        </p>
        <p className="mt-5 text-base font-light leading-8 text-orange-200 max-md:max-w-full">
          {eventDescE}
        </p>
      </div>
      <div className="flex flex-col self-end mt-8 text-base">
        <span>店长: {manager}</span>
        <time className="self-end mt-2.5">{postedDate}</time>
      </div>
    </div>
  </article>
);

const Event: React.FC = () => {
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const headerElement = document.querySelector('header');
    if (headerElement) {
      const heightInPx = headerElement.clientHeight;
      const heightInRem = heightInPx / 16; // 假设根字体大小为16px
      setHeaderHeight(heightInRem);
    }

    const handleResize = () => {
      if (headerElement) {
        const heightInPx = headerElement.clientHeight;
        const heightInRem = heightInPx / 16;
        setHeaderHeight(heightInRem);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const events = [
    {
      date: "2024.5.5",
      title: "2024.5.5 星光夜宴",
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/event1.png",
      altText: "Event image",
      eventDate: "5.5 Star Night Event",
      eventDescC:
        "在这个特别的夜晚，我们的酒吧将迎来一位神秘的明星嘉宾，为大家带来一场难忘的星光夜宴。不要错过这次与明星近距离接触的机会，赶快预约你的座位吧！",
      eventDescE:
        "On this special night, our bar will welcome a mysterious celebrity guest for an unforgettable starry night feast. Don't miss this opportunity to get up close and personal with the star, book your seat now!",
      manager: "Krueger Smith",
      postedDate: "2024.4.22",
      onClick: () => window.location.replace('/eventsub'),
    },
    {
      date: "2024.5.6",
      title: "2024.5.6 丰韵之夜",
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/event2.png",
      altText: "Event image",
      eventDate: "5.6 Sexy Night Event",
      eventDescC:
        "我们将于2024年5月5日邀请xx来我们的银座紫峰SHIHOO高级俱乐部,活动日期开始:2024年5月5日晚上19:00,活动时长3小时,请不要错过!",
      eventDescE:
        "We will invite xx to our club on May 5, 2024, the date of the event starts: May 5, 2024 at 19:00 pm, the event is 3 hours long, please do not miss it!",
      manager: "Krueger Smith",
      postedDate: "2024.4.22",
      onClick: () => window.location.replace('/eventsub'),
    },
    {
      date: "2024.5.5",
      title: "2024.5.5 星光夜宴",
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/event1.png",
      altText: "Event image",
      eventDate: "5.5 Star Night Event",
      eventDescC:
        "在这个特别的夜晚，我们的酒吧将迎来一位神秘的明星嘉宾，为大家带来一场难忘的星光夜宴。不要错过这次与明星近距离接触的机会，赶快预约你的座位吧！",
      eventDescE:
        "On this special night, our bar will welcome a mysterious celebrity guest for an unforgettable starry night feast. Don't miss this opportunity to get up close and personal with the star, book your seat now!",
      manager: "Krueger Smith",
      postedDate: "2024.4.22",
      onClick: () => window.location.replace('/eventsub'),
    },
    {
      date: "2024.5.6",
      title: "2024.5.6 丰韵之夜",
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/event2.png",
      altText: "Event image",
      eventDate: "5.6 Sexy Night Event",
      eventDescC:
        "我们将于2024年5月5日邀请xx来我们的银座紫峰SHIHOO高级俱乐部,活动日期开始:2024年5月5日晚上19:00,活动时长3小时,请不要错过!",
      eventDescE:
        "We will invite xx to our club on May 5, 2024, the date of the event starts: May 5, 2024 at 19:00 pm, the event is 3 hours long, please do not miss it!",
      manager: "Krueger Smith",
      postedDate: "2024.4.22",
      onClick: () => window.location.replace('/eventsub'),
    },
  ];

  return (
    <div className="bg-black max-w-[1440px] w-full mx-auto">
      <Header />
      <main
        className="flex flex-col items-center px-5 py-10"
        style={{ paddingTop: `${headerHeight}rem` }}
      >
        <header className="text-xl font-medium text-center text-orange-800">
          最新活动信息
        </header>
        <h1 className="mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
          Events News
        </h1>
        {events.map((event, index) => (
          <EventItem
            key={index}
            date={event.date}
            title={event.title}
            imgSrc={event.imgSrc}
            altText={event.altText}
            eventDate={event.eventDate}
            eventDescC={event.eventDescC}
            eventDescE={event.eventDescE}
            manager={event.manager}
            postedDate={event.postedDate}
            onClick={event.onClick}
          />
        ))}
      </main>
      <Footer />
    </div>
  );
};

export default Event; */


/* import { useNavigate } from "react-router-dom";
import Header from '../../component/Header';
import Footer from "../../component/Footer";

type EventProps = {
  date: string;
  title: string;
  imgSrc: string;
  altText: string;
  eventDate: string;
  eventDescC: string;
  eventDescE: string;
  manager: string;
  postedDate: string;
};

const EventItem: React.FC<EventProps> = ({
  date,
  title,
  imgSrc,
  altText,
  eventDate,
  eventDescC,
  eventDescE,
  manager,
  postedDate,
}) => (
  <article className="flex gap-5 max-md:flex-col max-md:gap-0 my-8">
    <figure className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <img
        loading="lazy"
        src={imgSrc}
        alt={altText}
        className="grow w-full aspect-[1.22] max-md:mt-10 max-md:max-w-full"
      />
    </figure>
    <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
      <h2 className="flex flex-col mt-7 text-4xl font-medium text-orange-100 leading-[56px] max-md:mt-10 max-md:max-w-full">
        <span className="max-md:max-w-full">{title}</span>
        <div className="flex flex-col self-end mt-5 mr-7 max-w-full text-neutral-400 w-[389px] max-md:mr-2.5">
          <img
            loading="lazy"
            src={imgSrc}
            alt={altText}
            className="self-end max-w-full border-fuchsia-600 border-solid aspect-[100] border-[3px] stroke-[3.306px] stroke-fuchsia-600 w-[283px]"
          />
          <figcaption className="mt-6">{eventDate}</figcaption>
        </div>
      </h2>
      <div className="text-left">
        <p className="mt-14 text-base leading-8 text-orange-200 max-md:mt-10 max-md:max-w-full">
          {eventDescC}
        </p>
        <p className="mt-5 text-base font-light leading-8 text-orange-200 max-md:max-w-full">
          {eventDescE}
        </p>
      </div>
      <div className="flex flex-col self-end mt-8 text-base">
        <span>店长: {manager}</span>
        <time className="self-end mt-2.5">{postedDate}</time>
      </div>
    </div>
  </article>
);

const Event: React.FC = () => {
  const navigate = useNavigate();

  const events = [
    {
      date: "2024.5.5",
      title: "2024.5.5 星光夜宴",
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/event1.png",
      altText: "Event image",
      eventDate: "5.5 Star Night Event",
      eventDescC:
        "在这个特别的夜晚，我们的酒吧将迎来一位神秘的明星嘉宾，为大家带来一场难忘的星光夜宴。不要错过这次与明星近距离接触的机会，赶快预约你的座位吧！",
      eventDescE:
        "On this special night, our bar will welcome a mysterious celebrity guest for an unforgettable starry night feast. Don't miss this opportunity to get up close and personal with the star, book your seat now!",
      manager: "Krueger Smith",
      postedDate: "2024.4.22",
    },
    {
      date: "2024.5.6",
      title: "2024.5.6 丰韵之夜",
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/event2.png",
      altText: "Event image",
      eventDate: "5.6 Sexy Night Event",
      eventDescC:
        "我们将于2024年5月5日邀请xx来我们的银座紫峰SHIHOO高级俱乐部,活动日期开始:2024年5月5日晚上19:00,活动时长3小时,请不要错过!",
      eventDescE:
        "We will invite xx to our club on May 5, 2024, the date of the event starts: May 5, 2024 at 19:00 pm, the event is 3 hours long, please do not miss it!",
      manager: "Krueger Smith",
      postedDate: "2024.4.22",
    },
    {
      date: "2024.5.5",
      title: "2024.5.5 星光夜宴",
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/event1.png",
      altText: "Event image",
      eventDate: "5.5 Star Night Event",
      eventDescC:
        "在这个特别的夜晚，我们的酒吧将迎来一位神秘的明星嘉宾，为大家带来一场难忘的星光夜宴。不要错过这次与明星近距离接触的机会，赶快预约你的座位吧！",
      eventDescE:
        "On this special night, our bar will welcome a mysterious celebrity guest for an unforgettable starry night feast. Don't miss this opportunity to get up close and personal with the star, book your seat now!",
      manager: "Krueger Smith",
      postedDate: "2024.4.22",
    },
    {
      date: "2024.5.6",
      title: "2024.5.6 丰韵之夜",
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/event2.png",
      altText: "Event image",
      eventDate: "5.6 Sexy Night Event",
      eventDescC:
        "我们将于2024年5月5日邀请xx来我们的银座紫峰SHIHOO高级俱乐部,活动日期开始:2024年5月5日晚上19:00,活动时长3小时,请不要错过!",
      eventDescE:
        "We will invite xx to our club on May 5, 2024, the date of the event starts: May 5, 2024 at 19:00 pm, the event is 3 hours long, please do not miss it!",
      manager: "Krueger Smith",
      postedDate: "2024.4.22",
    },
  ];

  return (
    <div className="bg-black">
      <Header />
      <main className="margin-top flex flex-col items-center px-5 py-10">
      <header className="text-xl font-medium text-center text-orange-800">
        最新活动信息
      </header>
      <h1
        className="mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl"
      >
        Events News
      </h1>
        {events.map((event, index) => (
          <EventItem
            key={index}
            date={event.date}
            title={event.title}
            imgSrc={event.imgSrc}
            altText={event.altText}
            eventDate={event.eventDate}
            eventDescC={event.eventDescC}
            eventDescE={event.eventDescE}
            manager={event.manager}
            postedDate={event.postedDate}
          />
        ))}
      </main>
      <Footer />
    </div>
  );
};

export default Event;
 */