import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './App.css';
import Home from './pages';
import Login from './pages/login';
import Admin from './pages/admin';
import Data from './pages/admin/data';
import Membership from './pages/membership';
import Artwork from './pages/admin/artwork';
import Ad from './pages/admin/ad';
import Tags from './pages/admin/tags';
import FirstLoad from './pages/firstLoad';
import User from './pages/admin/user';
import Usc from './pages/usc';
import Verify from './pages/verify';
import Updatepw from './pages/updatepw';
import { Toaster } from 'react-hot-toast';
import Auth from './component/Auth';
import useAuthStore from './authStore';
import useModalStore from "./modalStore";
import { useEffect, useState } from "react";
import { getProfile } from "./api";
import FirstLoadAnimation from "./Animation/FirstLoadAnimation";
import { RestoreRequestFilterSensitiveLog } from "@aws-sdk/client-s3";
import Event from "./pages/event";
import Eventsub from "./pages/eventsub";
import Events from './pages/admin/events';
import Info from './pages/info';
//import Fishtype from './pages/fishtype';
import FishType from './pages/fishType';
const publicAuth = ['guest', 'admin', 'vip', 'comomon', 'expired', 'need-verify'];
const adminAuth = ['admin'];
const vipAuth = ['admin', 'vip'];
const routes = [
  {
    path: "/",
    element: <Home />,
    allowedRoles: publicAuth,
  },
  // {
  //   path: "/",
  //   element: <FirstLoad />,
  //   allowedRoles: publicAuth,
  // },
  {
    path: "/verify",
    element: <Verify />,
    allowedRoles: publicAuth,
  },
  {
    path: "/updatepw",
    element: <Updatepw />,
    allowedRoles: vipAuth,
  },
  {
    path: "/login",
    element: <Login />,
    allowedRoles: publicAuth,
  },
  {
    path: "/admin",
    element: <Admin />,
    allowedRoles: adminAuth,
  },
  {
    path: "/tags",
    element: <Tags />,
    allowedRoles: adminAuth,
  },
  {
    path: "/ad",
    element: <Ad />,
    allowedRoles: adminAuth,
  },
  {
    path: "/user",
    element: <User />,
    allowedRoles: adminAuth,
  },
  {
    path: "/order",
    element: <Data />,
    allowedRoles: adminAuth,
  },
  {
    path: "/artwork/:animalId",
    element: <Artwork />,
    allowedRoles: adminAuth,
  },
  {
    path: "/usc",
    element: <Usc />,
    allowedRoles: publicAuth,
  },
  {
    path: "/membership",
    element: <Membership />,
    allowedRoles: publicAuth,
  },
  {
    path: "/event",
    element: <Event />,
    allowedRoles: publicAuth,
  },
  {
    path: "/eventsub",
    element: <Eventsub />,
    allowedRoles: publicAuth,
  },
  {
    path: "/events",
    element: <Events />,
    allowedRoles: adminAuth,
  },
  {
    path: "/info/:animalId",
    element: <Info />,
    allowedRoles: publicAuth,
  },
/*   {
    path: "/fishtype",
    element: <Fishtype />,
    allowedRoles: publicAuth,
  }, */
  {
    path: "/fishType",
    element: <FishType />,
    allowedRoles: publicAuth,
  },
  
]


// let allLoading = true;
// window.onload = () => {
//   console.log('onload');
//   setTimeout(() => { allLoading = false }, 1000)
// }


function App() {
  const { user, isLoggedIn, login, logout } = useAuthStore();
  const { isOpen } = useModalStore();
  const [appLoading, setApploading] = useState(true);
  const [allLoading, setAllLoading] = useState(true);

  useEffect(() => {

    const aToken = localStorage.getItem('aToken')
    if (!aToken) {
      setApploading(false)
    }
    // 获取用户信息
    if (!isLoggedIn) {
      getProfile().then((res) => {
        if (res?.data) {
          const { role, uid } = res.data || {}
          login({ role, uid })
        }
      }).catch(() => {

      }).finally(() => {
        setApploading(false)
      })
    } else {
      setApploading(false)
    }
    setTimeout(() => {
      setAllLoading(false)
    }, 2000)
  }, [])
  if (appLoading || allLoading) {
    return <div style={{ transition: 'all 1s' }} className="w-[100vw] h-[100vh] flex flex-col justify-center items-center">
      {/*<FirstLoadAnimation width={300} height={300} />*/}
    </div>
  }
  return (
    <div className={`App font-montserrat height-fill-available md:min-h-screen`}>
      <BrowserRouter>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.allowedRoles ? (
                  <Auth
                    allowedRoles={route.allowedRoles}
                  >
                    {route.element}
                  </Auth>
                ) : (
                  route.element
                )
              }
            />
          ))}
          {/* 未匹配的路由重定向到默认页面 */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
      {/* {isOpen ? <ContactModal /> : null} */}
    </div >
  );
}

export default App;
