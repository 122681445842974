import React from 'react';
import { Table, Space, Button, Image, Popover, message, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { payOneMonthVip } from '../../../../api';
interface DataTableProps {
  data: any[];
  loadData: any
}

const DataTable: React.FC<DataTableProps> = ({ data, loadData }) => {
  const navigate = useNavigate()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'uid',
      key: 'uid',
    },
    // {
    //   title: 'username',
    //   dataIndex: 'username',
    //   key: 'username',
    // },
    {
      title: '是否支付',
      dataIndex: 'ispaid',
      filters: [
        {
          text: '已支付',
          value: 'yes',
        },
        {
          text: '未支付',
          value: 'false',
        },
      ],
      onFilter: (value:any, record:any) => record.ispaid === value,
      render: (text: string) => {
        if (text === 'yes') {
          return '已支付'
        }
        if (text === 'false') {
          return '未支付'
        }
        if (text === 'no') {
          return '未支付'
        }
        return '未知'
      },
    },
    {
      title: 'User id',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: '注册时间',
      dataIndex: 'registTime',
      render: (text: string) => {
        if (!text) {
          return '无'
        }
        return new Date(Number(text))?.toLocaleString()
      },
      key: 'registTime',
    },
    {
      title: 'VIP过期时间',
      dataIndex: 'expire',
      render: (text: string) => {
        if (!text) {
          return '无'
        }
        return new Date(Number(text))?.toLocaleString()
      },
      key: 'expire',
    },
    {
      title: 'email',
      dataIndex: 'email',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search name"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <button onClick={() => confirm()}>Search</button>
          <button onClick={() => clearFilters()}>Reset</button>
        </div>
      ),
      onFilter: (value:any, record:any) => record?.email.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'password',
      dataIndex: 'password',
      key: 'password',
    },
    // {
    //   title: 'password',
    //   dataIndex: 'password',
    //   key: 'password',
    // },
    {
      title: '身份',
      dataIndex: 'admin',
      filters: [
        {
          text: '管理员',
          value: 'yes',
        },
      ],
      onFilter: (value:any, record:any) => record.admin === value,
      render: (text: string) => {
        if (text === 'yes') {
          return '管理员'
        }
        return '用户'
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      filters: [
        {
          text: '正常',
          value: 1,
        },
        {
          text: '封禁',
          value: 0,
        },
      ],
      onFilter: (value:any, record:any) => record.status === value,

      render: (text: number) => {
        if (text === 1) {
          return '正常'
        }
        return '封禁'
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button onClick={async () => {
            const res = await payOneMonthVip({ uid: record.uid, removeEx: true })
            if (res.data === 'ok') {
              message.success('removed')
              loadData()
              return
            }
          }}>remove vip</Button>
          <Button onClick={async () => {
            const res = await payOneMonthVip({ uid: record.uid, month: 1 })
            if (res.data === 'ok') {
              message.success('added')
              loadData()
              return
            }
            message.success('error')
          }}>pay one month vip</Button>
          <Button onClick={async () => {
            const res = await payOneMonthVip({ uid: record.uid, status: 0 })
            if (res.data === 'ok') {
              message.success('updated')
              loadData()
              return
            }
            message.success('error')
          }} >限制账户</Button>
          <Button onClick={async () => {
            const res = await payOneMonthVip({ uid: record.uid, status: 1 })
            if (res.data === 'ok') {
              message.success('updated')
              loadData()
              return
            }
            message.success('error')
          }} >解除限制</Button>
        </Space>
      ),
    },

  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: 'max-content' }}
      pagination={{ 
        pageSizeOptions: ['10', '25', '50'],
        showSizeChanger: true,
      }} 
    />
  );
};

export default DataTable;
