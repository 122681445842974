import React, { useEffect } from 'react';
import { Field, ErrorMessage, Formik, Form } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useNavigate, Navigate } from "react-router-dom";
import { getCookie, setLocalStorage } from '../../grocery/utils';
import Header from '../../component/Header';
import BackGround from '../../component/BackGround';
import CustomInputField from '../../component/CustomInputField';
import { getProfile, login } from '../../api';
import useAuthStore from '../../authStore';
// Create a Custom Input Field component

interface FormValues {
  email: string;
  password: string;
}

// Validation schema with Yup
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string()
    .required('Required')
    .matches(/^(?=.*[a-zA-Z])(?=.*\d)/, 'invalid password')
    .min(8, 'At least 8 characters')
});

async function signin(values: FormValues, navigate: any) {
  try {
    const res = await login(values)
    if (res?.data) {
      toast.success(res?.data?.msg)
      setLocalStorage('aToken', res?.data?.aToken)
      return true
    }
    return false
  } catch (error: any) {
    toast.error(error?.response?.data || 'Somthing wrong!')
    const { email } = values
    if (error?.response?.data === 'Email not verified.') {
      navigate(`/verify?email=${email}`)
    }
    console.error('Error:', error);
    return false
  }
}

const MyForm: React.FC = () => {
  const navigate = useNavigate()
  const { user, isLoggedIn, login, logout } = useAuthStore();

  if (isLoggedIn) {
    return <div className='height-fill-available md:min-h-screen'>
      <Header />
      <BackGround />
      <div className='flex flex-col justify-center items-center md:mt-8'>
        <h2 className='font-montserrat text-3xl font-bold mb-4 md:mb-8' >You had logged in!</h2>
        <h2 className='font-montserrat text-3xl font-bold mb-4 md:mb-8 cursor-pointer' onClick={() => {
          navigate('/admin')
        }}>Go to admin</h2>
      </div>
    </div>
  }
  return (
    <div className='height-fill-available md:min-h-screen'>
      <Header />
      <BackGround />
      <div className='flex flex-col justify-center items-center mt-16 md:mt-[100px]'>
        <Formik
          initialValues={{
            email: '',
            password: '',
            // agreeToTerms: false,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values: FormValues, { setSubmitting }) => {
            const flag = await signin(values, navigate);
            setSubmitting(false);
            if (flag) {
              getProfile().then((res: any) => {
                console.log('res', res);
                if (res?.data) {
                  const { role, uid } = res.data || {}
                  login({ role, uid })
                  setTimeout(() => {
                    navigate('/')
                  }, 300)
                }
              }).catch(() => {

              })
            }
          }}
        >
          {() => (
            <Form className='w-[80%] md:w-[400px]'>
              <CustomInputField name="email" type="email" placeholder="Email" />
              <CustomInputField name="password" type="password" placeholder="Password" />
              <button
                type="submit"
                className="mb-4 bg-gradient-to-r from-[#F093FB] to-[#F5576C] text-white font-montserrat flex items-center justify-center py-2 px-4 rounded-full w-full"
              >
                Sign In
              </button>

            </Form>
          )}
        </Formik>
      {/*   <div>
          <p className="text-gray-600">
            Not a <span onClick={() => {
              navigate('/register')
            }} className="text-pink-500 hover:underline cursor-pointer">member</span> now?
          </p>
          <p className="text-gray-600">
            <span onClick={() => {
              navigate('/resetpw')
            }} className="text-pink-500 hover:underline cursor-pointer">forget password ?</span>
          </p>

        </div> */}
      </div>
    </div>
  )

};

export default MyForm;
