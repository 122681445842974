// src/App.tsx
import React, { useState, useEffect } from 'react';
import { Button, Modal, message } from 'antd';
import EventForm from '../_components/EventForm'; // 新增的导入
import { addEvent, getEvents, removeEvent } from '../../../api'; // 新增的导入
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import NavigationButtons from '../_components/NavigationButtons';
import UserProfileIcon from '../../../component/UserProfileIcon';
import EventDataTable from './_components/DataTable'; // 导入事件数据表格组件

function Admin() {
  const [isVisible, setIsVisible] = useState(false);
  const [events, setEvents] = useState([]); // 新增的状态
  const [initialData, setInitialData] = useState<any>({});
  const [mode, setMode] = useState<'edit' | 'create'>('create');
  const navigate = useNavigate();

  const loadData = () => {
    getEvents().then((res) => {
      setEvents(res?.data);
    }).catch().finally();
  };

  const refreshData = () => {
    getEvents().then((res) => {
      setEvents(res?.data);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!isVisible) {
      setInitialData({});
    }
  }, [isVisible]);

  const handleSave = async (data: any) => {
    try {
      const res = await addEvent(data);
      res?.data?.msg && toast.success(res.data.msg);
      refreshData();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="Admin bg-white min-h-[100vh] p-4">
      <div className='flex justify-center items-center'>
        <NavigationButtons />
        <Button className='m-2' onClick={() => { setMode('create'); setIsVisible(true); }}>Create Event</Button>
        <div className="m-2">
          <UserProfileIcon />
        </div>
      </div>
      <div className='m-2 mb-[100px]'>
        <EventDataTable
          data={events}
          onEdit={async (record: any) => {
            await setInitialData(record);
            await setMode('edit');
            setIsVisible(true);
          }}
          onDelete={(id) => {
            removeEvent({ id: id.toString() }).then((res: any) => {
              message.info(res?.data?.msg);
              loadData();
            });
          }}
        />
      </div>
      <Modal
        open={isVisible}
        title={mode === 'create' ? 'Create Form' : 'Edit Form'}
        onCancel={() => setIsVisible(false)}
        footer={null}
      >
        <EventForm
          mode={mode}
          initialData={initialData}
          onSave={handleSave}
        />
      </Modal>
    </div>
  );
}

export default Admin;
/* import React, { useState, useEffect } from 'react';
import { getEvents, removeEvent } from '../../../api';
import EventDataTable from './_components/DataTable';
import NavigationButtons from '../_components/NavigationButtons';
import { message } from 'antd';

function EventData() {
  const [eventList, setEventList] = useState([]);

  const loadData = () => {
    getEvents().then((res) => {
      setEventList(res?.data);
    }).catch().finally();
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleDelete = async (id: string | number) => {
    try {
      const res = await removeEvent({ id });
      if (res?.data?.msg) {
        message.success(res.data.msg);
        loadData();  // Reload data after successful deletion
      }
    } catch (error) {
      console.error('Error deleting event:', error);
      message.error('Failed to delete event.');
    }
  };

  const handleEdit = (record: any) => {
    // Implement the logic to handle edit action here
    console.log('Edit record:', record);
  };

  return (
    <div className="Artwork bg-white min-h-[100vh] p-4 pb-[100px]">
      <div className='mb-2'>
        <NavigationButtons />
      </div>
      <EventDataTable data={eventList} onDelete={handleDelete} onEdit={handleEdit} />
    </div>
  );
}

export default EventData;
 */
