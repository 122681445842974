import request from './request';

export const login = (data: any) => {
  return request({
    url: '/login',
    method: 'post',
    data
  });
}

export const likeById = (data: any) => {
  return request({
    url: '/likeById',
    method: 'post',
    data
  });
}

export const getLikesSumGroupByWid = () => {
  return request({
    url: '/getLikesSumGroupByWid',
    method: 'get'
  });
}

export const getProfile = () => {
  return request({
    url: '/getProfile',
    method: 'get'
  });
}

export const getUser = () => {
  return request({
    url: '/getUser',
    method: 'get'
  });
}

export const getOrder = () => {
  return request({
    url: '/getOrder',
    method: 'get'
  });
}

export const payForVip = (data?: any) => {
  return request({
    url: '/payForVip',
    method: 'post',
    data
  });
}

export const updateUser = (data: any) => {
  return request({
    url: '/updateUser',
    method: 'post',
    data
  });
}

export const payOneMonthVip = (data: any) => {
  return request({
    url: '/payOneMonthVip',
    method: 'post',
    data
  });
}