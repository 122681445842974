// src/MyForm.tsx
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import ImageUpload from '../../../../component/ImageUpload';
import VideoUpload from '../../../../component/VideoUpload';
import { SketchPicker } from 'react-color';
interface Props {
  mode: 'edit' | 'create';
  initialData?: any
  onSave: (data: any) => void;
}

const MyForm: React.FC<Props> = ({ mode, initialData, onSave }) => {
  const [form] = Form.useForm();
  const [color, setColor] = useState('#000000');
  const onFinish = (values: any) => {
    onSave(values);
  };
  const handleImageUploadSuccess = (url: string, fieldName: string) => {
    form.setFieldsValue({
      [fieldName]: url
    });
  };
  const handleChange = (newColor: any) => {
    setColor(newColor.hex);
    form.setFieldsValue({ bgcolor: newColor.hex });
  };

  useEffect(() => {
    if (initialData?.id) {
      form.setFieldsValue({
        ...initialData,
      });
    }
  }, [initialData])
  return (
    <Form
      form={form}
      initialValues={initialData}
      onFinish={onFinish}
    >
      {initialData?.id ? <Form.Item name="id" label="id" >
        <Input disabled />
      </Form.Item> : null}
      <Form.Item name="banner" label="Image Url" rules={[{ required: true, message: 'Please upload an image!' }]}>
        <ImageUpload initialImageUrl={initialData?.banner} onUploadSuccess={(url) => handleImageUploadSuccess(url, 'banner')} />
      </Form.Item>
      <Form.Item name="avatar" label="Avatar" rules={[{ required: true, message: 'Please upload an avatar!' }]}>
        <ImageUpload initialImageUrl={initialData?.avatar} onUploadSuccess={(url) => handleImageUploadSuccess(url, 'avatar')} />
      </Form.Item>
      <Form.Item name="text" label="Text" rules={[{ required: true, message: 'Please input the text!' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="url" label="Url" rules={[{ required: true, message: 'Please input the url!' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="bg color"
        name="bgcolor"
        initialValue={color}
      >
        <Input readOnly />
      </Form.Item>
      <SketchPicker color={color} onChange={handleChange} className='my-2' />
      <Form.Item name="remark" label="description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className='text-black'>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MyForm;
