import Header from "../../component/Header";
function About() {
  return (
    <div className="bg-[#181818]">
      <Header />
      <div className="flex  flex-col justify-center items-center mb-4">
        <p className="text-2xl md:text-5xl font-montserrat-extra-bold text-[#FF548B] w-[80%] md:w-[800px]">18 USC 2257 STATEMENT</p>
        {/* <pre className="text-sm md:text-xl text-left w-[80%] md:w-[800px] my-8">{terms1}</pre> */}
        <p style={{ marginBottom: 200 }} className="text-sm mt-4 md:text-xl text-left w-[80%] md:w-[1000px]">
          All models, actors, actresses and other persons that appear in any visual depiction of actual sexually explicit conduct appearing or otherwise contained in this Website were over the age of eighteen years at the time of the creation of such depictions. With respect to all visual depictions displayed on this website, whether of actual sexually explicit conduct, simulated sexual content or otherwise, all persons in said visual depictions were at least 18 years of age when said visual depictions were created. Some visual depictions displayed on this Website are exempt from the provision of 18 U.S.C. section 2257 and 28 C.F.R. 75 because said visual depictions do not consist of conduct as specifically listed in 18 U.S.C. section 2256 (2) A-D, but are simply depictions of non-sexually explicit nudity, or are otherwise exempt because the visual depictions were created prior to July 3, 1995. The owners and operators of this Website are the primary producers, as that term is defined in 18 USC section 2257, 75.1 (c) 1-2, of the visual content contained in the Website. Please direct questions pertaining to content on this website to: Webmaster. (The “Webmaster” will be a hyperlink to your e-mail for customer service) The images contained on this site were, duplicated, reproduced or reissued on 201. (Ideally, your Webmaster will program a calendar application which will automatically change this date on a daily basis so that it is current. If you cannot program this feature, or choose not to, omit this sentence from your 2257 compliance). The original records required pursuant to 18 U.S.C. section 2257 and 28 C.F.R. 75 for materials contained in the website are kept by the appropriate Custodian of Records as follows: Hungereye Inc. P.O.Box 23973 1687 Nicosia Cyprus
          <br />
        </p>


      </div>
    </div>
  );
}

export default About;
