// src/App.tsx
import React, { useState, useEffect } from 'react';
import { Button, Modal, message } from 'antd';
import TagsForm from './_components/TagsForm';
import DataTable from './_components/DataTable';
import { addAnimalTag, getArtistsTag, moveTag } from '../../../api';
import { useNavigate } from 'react-router-dom';
import NavigationButtons from '../_components/NavigationButtons';

function Artwork() {
  const [isVisible, setIsVisible] = useState(false);
  const [arts, setArts] = useState([]);
  const [mode, setMode] = useState<'edit' | 'create'>('create');
  const navigate = useNavigate()
  const loadData = () => {
    getArtistsTag().then((res) => {
      console.log('res', res);
      setArts(res?.data)
    }).catch().finally()
  }

  useEffect(() => {
    loadData()
  }, [])

  const handleSave = async (data: any) => {
    // 这里处理保存逻辑，比如发送到后端等。
    try {
      const res = await addAnimalTag(data)
      console.log('res', res);
      setIsVisible(false);
      loadData()
    } catch (error) {
      console.log('error', error);
    }

  };

  return (
    <div className="Artwork bg-white min-h-[100vh] pb-[100px]">
      <NavigationButtons />
      <Button className='m-2' onClick={() => { navigate('/admin') }}>Back</Button>
      <Button className='m-2' onClick={() => { setMode('create'); setIsVisible(true); }}>Create Tag</Button>
      <div className='m-2'>
        <DataTable
          data={arts}
          onEdit={(id) => { }}
          onDelete={(id) => {
            moveTag({ id }).then((res: any) => {
              message.info(res?.data?.msg)
              loadData()
            })
          }}
        />
      </div>
      <Modal
        open={isVisible}
        title={mode === 'create' ? 'Create Tag' : 'Edit Tag'}
        onCancel={() => setIsVisible(false)}
        footer={null}
      >
        <TagsForm
          // initialData={mode === 'edit' ? initialData : undefined}
          onSave={handleSave}
        />
      </Modal>
    </div>
  );
}

export default Artwork;
