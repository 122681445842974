import React, { useState, useEffect } from 'react';

type QuickLink = {
  key: string;
  mainText: string;
  subText: string;
  mainTextClass?: string;
  subTextClass?: string;
};

type Address = {
  line1: string;
  line2: string;
};

const quickLinks: QuickLink[] = [
  { key: "aboutUs", mainText: "About US", subText: "关于我们", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  { key: "aboutGirls", mainText: "About Girls", subText: "关于女公关", mainTextClass: "text-customGold text-sm mt-3", subTextClass: "text-customGold text-md" },
  { key: "eventsNews", mainText: "Events News", subText: "最新信息", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  { key: "recruit", mainText: "Recruit", subText: "求人情報", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
];

const address: Address = {
  line1: "東京都中央区銀座8丁目3番11号 和恒ビル3階B部分",
  line2: "WAKO BLD., 3F, 8-chōme-3-11 Ginza, Chuo City, Tokyo 104-0061",
};

const Footer: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (key: string) => {
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleReservationClick = () => {
    window.location.href = "https://forms.gle/9uZrBhn4s3CCek9W9";
  };

  return (
    <footer className="w-full bg-zinc-900 max-md:max-w-full">
      <div className="flex justify-center mt-2 text-xs leading-6 self-stretch px-16 pt-10 pb-4 mt-40 w-full text-white max-md:mt-2.5">
        Copyright © 2024 Aquashop Tier1
      </div>
    </footer>
  );
};

export default Footer;