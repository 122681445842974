// src/App.tsx
import React, { useState, useEffect } from 'react';
import { Button, Modal, message } from 'antd';
import ArtForm from './_components/ArtForm';
import { addAnimalArtwork, getArtistsArtwork, getArtistss, removeArtwork, getArtistsArtworkByRandom } from '../../../api';
import { useParams, useNavigate } from 'react-router-dom';
import DataTable from './_components/DataTable';
import MediaList from './_components/MediaList';
import NavigationButtons from '../_components/NavigationButtons';

function Artwork() {
  const [isVisible, setIsVisible] = useState(false);
  const [animal, setAnimal] = useState<any>({});
  const [arts, setArts] = useState([]);
  const [mode, setMode] = useState<'edit' | 'create-image' | 'create-video'>('create-image');
  const params = useParams()
  const navigate = useNavigate()
  const loadData = () => {
    console.log('params', params);
    if (params?.animalId) {
      getArtistss({
        id: params.animalId
      }).then((res) => {
        setAnimal(res?.data?.[0])
      }).catch().finally()
      getArtistsArtwork({ artistsId: params.animalId }).then((res) => {
        setArts(res?.data)
      }).catch().finally()
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const initialData = {
    username: "Test Name",
    description: "Test Description",
    banner: "",
    avatar: ""
  }; // 这只是示例数据，你可能需要从API或其他地方获取真实的数据。

  const handleSave = async (data: any) => {
    // 这里处理保存逻辑，比如发送到后端等。
    try {
      const extradata = {
        artistsId: params?.animalId,
        artistsName: animal?.username || '',
        tags: animal?.tags || '',
        mediaType: params?.videoUrl ? 'video' : 'image',
        likes: 0,
      }
      const res = await addAnimalArtwork({ ...data, ...extradata })
      console.log('res', res);
      loadData()
    } catch (error) {
      // setIsVisible(true);
      console.log('error', error);
    }

  };

  return (
    <div className="Artwork bg-white min-h-[100vh] pb-[100px]">
      <NavigationButtons />
      <Button className='m-2' onClick={() => { navigate('/admin') }}>Back</Button>
      <Button className='m-2' onClick={() => { setMode('create-image'); setIsVisible(true); }}>Create Images (batch)</Button>
      <Button className='m-2' onClick={() => { setMode('create-video'); setIsVisible(true); }}>Create Image and video (single)</Button>
      <div className='m-2'>
        <MediaList
          data={arts}
          onEdit={() => { }}
          onDelete={(id: any) => {
            removeArtwork({ id: id.toString() }).then((res) => {
              message.info(res?.data?.msg)
              loadData()
            })
          }} />
      </div>
      {/* <div className='m-2'>
        <DataTable
          data={arts}
          onEdit={() => { }}
          onDelete={(id: any) => {
            removeArtwork({ id: id.toString() }).then((res) => {
              message.info(res?.data?.msg)
              loadData()
            })
          }}
        />
      </div> */}
      <Modal
        open={isVisible}
        title='create'
        onCancel={() => {
          setIsVisible(false)
          // setArts([])
        }}
        footer={null}
      >
        <ArtForm
          mode={mode}
          initialData={mode === 'edit' ? initialData : undefined}
          onSave={handleSave}
          loadData={loadData}
          setIsVisible={setIsVisible}
          animal={animal}
          params={params}
        />
      </Modal>
    </div>
  );
}

export default Artwork;
