import React from 'react';
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, EffectCoverflow, Keyboard, Mousewheel } from 'swiper';
import { useNavigate } from 'react-router-dom';

// Install modules
SwiperCore.use([Pagination, EffectCoverflow, Keyboard, Mousewheel]);

const AquaticAnimalsComponent = () => {

  const navigate = useNavigate();

  const handleTypeClick = (type: string) => {
    navigate(`/fishType?type=${type}`);
  };
  
  return (
    <>
      <style>{`
        @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Roboto:wght@300;400;500;900&display=swap");

        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: "Open Sans", sans-serif;
        }

        main {
          position: relative;
          width: calc(min(90rem, 90%));
          margin: 0 auto;
          min-height: 100vh;
          column-gap: 3rem;
          padding-block: min(20vh, 3rem);
        }

        .bg {
          position: fixed;
          top: -4rem;
          left: -12rem;
          z-index: -1;
          opacity: 0;
        }

        .bg2 {
          position: fixed;
          bottom: -2rem;
          right: -3rem;
          z-index: -1;
          width: 9.375rem;
          opacity: 0;
        }

        main > div span {
          text-transform: uppercase;
          letter-spacing: 1.5px;
          font-size: 1rem;
          color: #717171;
        }

        main > div h1 {
          text-transform: capitalize;
          letter-spacing: 0.8px;
          font-family: "Roboto", sans-serif;
          font-weight: 900;
          font-size: clamp(3.4375rem, 3.25rem + 0.75vw, 4rem);
          background-color: #005baa;
          background-image: linear-gradient(45deg, #005baa, #000000);
          background-size: 100%;
          background-repeat: repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
        }

        main > div hr {
          display: block;
          background: #005baa;
          height: 0.25rem;
          width: 6.25rem;
          border: none;
          margin: 1.125rem 0 1.875rem 0;
        }

        main > div p {
          line-height: 1.6;
        }

        main a {
          display: inline-block;
          text-decoration: none;
          text-transform: uppercase;
          color: #717171;
          font-weight: 500;
          background: #fff;
          border-radius: 3.125rem;
          transition: 0.3s ease-in-out;
        }

        main > div > a {
          border: 2px solid #c2c2c2;
          margin-top: 2.188rem;
          padding: 0.625rem 1.875rem;
        }

        main > div > a:hover {
          border: 0.125rem solid #005baa;
          color: #005baa;
        }

        .swiper {
          width: 100%;
          padding-top: 3.125rem;
        }

        .swiper-pagination-bullet,
        .swiper-pagination-bullet-active {
          background: #fff;
        }

        .swiper-pagination {
          bottom: 1.25rem !important;
        }

        .swiper-slide {
          width: 18.75rem;
          height: 28.125rem;
          display: flex;
          flex-direction: column;
          justify-content: end;
          align-items: self-start;
        }

        .swiper-slide h2 {
          color: #fff;
          font-family: "Roboto", sans-serif;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.4;
          margin-bottom: 0.625rem;
          padding: 0 0 0 1.563rem;
          text-transform: uppercase;
        }

        .swiper-slide p {
          color: #dadada;
          font-family: "Roboto", sans-serif;
          font-weight: 300;
          padding: 0 1.563rem;
          line-height: 1.6;
          font-size: 0.75rem;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .swiper-slide a {
          margin: 1.25rem 1.563rem 3.438rem 1.563rem;
          padding: 0.438em 1.875rem;
          font-size: 0.9rem;
        }

        .swiper-slide a:hover {
          color: #005baa;
        }

        .swiper-slide div {
          display: none;
          opacity: 0;
          padding-bottom: 0.625rem;
        }

        .swiper-slide-active div {
          display: block;
          opacity: 1;
        }

        .swiper-slide--one {
          background: linear-gradient(to top, #0f2027, #203a4300, #2c536400),
            url("https://tier1.sgp1.cdn.digitaloceanspaces.com/static/IMG_9665.JPG")
              no-repeat 50% 50% / cover;
        }

        .swiper-slide--two {
          background: linear-gradient(to top, #0f2027, #203a4300, #2c536400),
            url("https://tier1.sgp1.cdn.digitaloceanspaces.com/static/IMG_0009.jpg")
              no-repeat 50% 50% / cover;
        }

        .swiper-slide--three {
          background: linear-gradient(to top, #0f2027, #203a4300, #2c536400),
            url("https://images.unsplash.com/photo-1545671913-b89ac1b4ac10?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")
              no-repeat 50% 50% / cover;
        }
        .swiper-3d .swiper-slide-shadow-left,
        .swiper-3d .swiper-slide-shadow-right {
          background-image: none;
        }

        @media screen and (min-width: 48rem) {
          main {
            display: flex;
            align-items: center;
          }
          .bg,
          .bg2 {
            opacity: 0.1;
          }
        }

        @media screen and (min-width: 93.75rem) {
          .swiper {
            width: 85%;
          }
        }
      `}</style>

      <main className="relative">
        <div className="text-center py-12 bg-gradient-to-b from-black-800 to-black-500 text-white">
          <span className="block text-lg uppercase tracking-widest">Discover</span>
          <h1 className="text-5xl font-bold my-4">Aquashop Tier1</h1>
          <hr className="w-16 mx-auto border-2 border-white my-4" />
          <p className="text-lg max-w-2xl mx-auto">
           Explore with us to know about Aquatic Animals.
          </p>
      {/*     <a
            href="#"
            className="mt-6 inline-block bg-pink-600 text-white px-6 py-3 rounded-full text-lg font-semibold"
          >
            Download App
          </a> */}
        </div>

        <Swiper
          spaceBetween={30}
          pagination={{ clickable: true }}
          effect="coverflow"
          centeredSlides={true}
          loop={true}
          keyboard={{ enabled: true }}
          mousewheel={{ forceToAxis: true }}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 1 },
            1024: { slidesPerView: 2 },
            1560: { slidesPerView: 3 },
          }}
          className="my-16 swiper"
        >
          <SwiperSlide className="flex justify-center items-center bg-cover bg-center swiper-slide--one">
            <div className="p-8 rounded-lg max-w-md">
              <h2 className="text-2xl font-bold mb-4">カラシン</h2>
              <p className="mb-4">
                南米、アフリカ
              </p>
              <button onClick={() => handleTypeClick('カラシン')} className="text-blue-500 underline">Explore</button>
            </div>
          </SwiperSlide>
          <SwiperSlide className="flex justify-center items-center bg-cover bg-center swiper-slide--two">
            <div className="p-8 rounded-lg max-w-md">
              <h2 className="text-2xl font-bold mb-4">コリドラス</h2>
              <p className="mb-4">
                南米
              </p>
              {/* <a href="https://en.wikipedia.org/wiki/Seahorse" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Explore</a> */}
              <button onClick={() => handleTypeClick('コリドラス')} className="text-blue-500 underline">Explore</button>
            </div>
          </SwiperSlide>
          <SwiperSlide className="flex justify-center items-center bg-cover bg-center swiper-slide--three">
            <div className="p-8 rounded-lg max-w-md">
              <h2 className="text-2xl font-bold mb-4">その他</h2>
              <p className="mb-4">
                other fish and someting
              </p>
              {/* <a href="https://en.wikipedia.org/wiki/Octopus" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Explore</a> */}
              <button onClick={() => handleTypeClick('その他')} className="text-blue-500 underline">Explore</button>
            </div>
          </SwiperSlide>
        </Swiper>
      </main>
    </>
  );
};

export default AquaticAnimalsComponent;



/* import React from 'react';
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Pagination]);

const AquaticAnimalsComponent = () => {
  return (
    <main className="relative">
      <div className="text-center py-12 bg-gradient-to-b from-blue-800 to-blue-500 text-white">
        <span className="block text-lg uppercase tracking-widest">Discover</span>
        <h1 className="text-5xl font-bold my-4">Aquatic Animals</h1>
        <hr className="w-16 mx-auto border-2 border-white my-4" />
        <p className="text-lg max-w-2xl mx-auto">
          Beauty and mystery are hidden under the sea. Explore with our application to know about Aquatic Animals.
        </p>
        <a
          href="#"
          className="mt-6 inline-block bg-pink-600 text-white px-6 py-3 rounded-full text-lg font-semibold"
        >
          Download App
        </a>
      </div>

      <Swiper
        spaceBetween={30}
        pagination={{ clickable: true }}
        className="my-16"
      >
        <SwiperSlide className="flex justify-center items-center bg-cover bg-center" style={{ backgroundImage: 'url(https://tier1.sgp1.cdn.digitaloceanspaces.com/static/IMG_9665.JPG)' }}>
          <div className="bg-white bg-opacity-70 p-8 rounded-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4">Jellyfish</h2>
            <p className="mb-4">
              Jellyfish and sea jellies are the informal common names given to the medusa-phase of certain gelatinous members of the subphylum Medusozoa, a major part of the phylum Cnidaria.
            </p>
            <a href="https://en.wikipedia.org/wiki/Jellyfish" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Explore</a>
          </div>
        </SwiperSlide>
        <SwiperSlide className="flex justify-center items-center bg-cover bg-center" style={{ backgroundImage: 'url(https://cdn.pixabay.com/photo/2012/04/13/13/57/scallop-32506_960_720.png)' }}>
          <div className="bg-white bg-opacity-70 p-8 rounded-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4">Seahorse</h2>
            <p className="mb-4">
              Seahorses are mainly found in shallow tropical and temperate salt water throughout the world. They live in sheltered areas such as seagrass beds, estuaries, coral reefs, and mangroves.
            </p>
            <a href="https://en.wikipedia.org/wiki/Seahorse" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Explore</a>
          </div>
        </SwiperSlide>
        <SwiperSlide className="flex justify-center items-center bg-cover bg-center" style={{ backgroundImage: 'url(https://cdn.pixabay.com/photo/2021/11/04/19/39/jellyfish-6769173_960_720.png)' }}>
          <div className="bg-white bg-opacity-70 p-8 rounded-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4">Octopus</h2>
            <p className="mb-4">
              Octopuses inhabit various regions of the ocean, including coral reefs, pelagic waters, and the seabed; some live in the intertidal zone and others at abyssal depths.
            </p>
            <a href="https://en.wikipedia.org/wiki/Octopus" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Explore</a>
          </div>
        </SwiperSlide>
        <SwiperSlide className="flex justify-center items-center bg-cover bg-center" style={{ backgroundImage: 'url(https://cdn.pixabay.com/photo/2021/11/04/19/39/jellyfish-6769173_960_720.png)' }}>
          <div className="bg-white bg-opacity-70 p-8 rounded-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4">Shark</h2>
            <p className="mb-4">
              Sharks are a group of elasmobranch fish characterized by a cartilaginous skeleton, five to seven gill slits on the sides of the head, and pectoral fins that are not fused to the head.
            </p>
            <a href="https://en.wikipedia.org/wiki/Shark" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Explore</a>
          </div>
        </SwiperSlide>
        <SwiperSlide className="flex justify-center items-center bg-cover bg-center" style={{ backgroundImage: 'url(https://cdn.pixabay.com/photo/2021/11/04/19/39/jellyfish-6769173_960_720.png)' }}>
          <div className="bg-white bg-opacity-70 p-8 rounded-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4">Dolphin</h2>
            <p className="mb-4">
              Dolphins are widespread. Most species prefer the warm waters of the tropic zones, but some, such as the right whale dolphin, prefer colder climates.
            </p>
            <a href="https://en.wikipedia.org/wiki/Dolphin" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Explore</a>
          </div>
        </SwiperSlide>
      </Swiper>
    </main>
  );
};

export default AquaticAnimalsComponent;
 */