import { number } from 'yup';
import { getArtistsArtwork } from './index';
import request from './request';
import { type } from 'os';

type InuptType = {
  uid: number;
  aid?: number;
}

export const getArtistsArtworkAndLike = async ({ uid, aid }: InuptType) => {
  let mList: any = []
  const allList = await getArtistsArtwork({ aid })
  const likeList = await getArtistsArtwork({ uid })
  mList = allList?.data.map((item: any) => {
    const temp = likeList?.data?.find((r: any) => (r.wid === item.id && item.artistsId === r.aid))
    if (temp) {
      return {
        ...item,
        isMylike: true,
      }
    }
    return item
  })
  return mList
}

type TagInuptType = {
  uid: number;
  tag?: string;
  name?: string;
}

export const getListByTag = async ({ tag, uid, name }: any) => {
  let mList: any = []
  const allList = await getArtistsArtworkByTag({ tag, name })
  const likeList = await getArtistsArtwork({ uid })
  mList = allList?.data.map((item: any) => {
    const temp = likeList?.data?.find((r: any) => (r.wid === item.id && item.artistsId === r.aid))
    if (temp) {
      return {
        ...item,
        isMylike: true,
      }
    }
    return item
  })
  return mList
}


export const updateLikeList = (mediaList: any, id: number, num: number) => {
  const newList = mediaList.map((m: any) => {
    if (m.id === id) {
      let isMylike = false;
      if (num === 1) {
        isMylike = true;
      } else {
        isMylike = false;
      }
      return {
        ...m,
        isMylike,
        totalLikes: Number(m?.totalLikes || 0) + num
      }
    }
    return m
  })
  return newList
}

export const getMylikes = (params: any) => {
  return request({
    url: '/getMylikes',
    method: 'get',
    params,
  });
}

export const getMylikesPlus = (params: any) => {
  return request({
    url: '/getMylikesPlus',
    method: 'get',
    params,
  });
}
export const getArtistsArtworkByTag = (params: any) => {
  return request({
    url: '/getArtistsArtworkByTag',
    method: 'get',
    params,
  });
}