import React from 'react';
import { Card, Button, Image, Popover } from 'antd';
import { PlayCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

interface Media {
  id: string;
  username: string;
  imageUrl: string;
  videoUrl?: string; // 添加视频URL属性
}

interface MediaListProps {
  data: Media[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

const MediaList: React.FC<MediaListProps> = ({ data, onDelete, onEdit }) => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-6 gap-4">
      {data.map((media) => (
        <Card
          className='relative'
          key={media.id}
          cover={
            <Popover
              content={
                media.videoUrl ? (
                  <video controls width={300} src={media.videoUrl} />
                ) : (
                  <Image width={200} src={media.imageUrl} />
                )
              }
            >
              {media.videoUrl ? (
                <div className="absolute top-0 right-0">
                  <Button
                    className="rounded-full"
                    shape="circle"
                    icon={<PlayCircleOutlined className='text-white' />} // 请确保导入PlayCircleOutlined
                  />
                </div>
              ) : null}
              <Image width={300} src={media.imageUrl} alt="Media" className='object-cover h-full' />
            </Popover>
          }
        >
          <Card.Meta
            className='bg-[rgba(255,255,255,0.6)] right-0 top-0 z-1 absolute p-2 text-white'
            title={media.id}
            avatar={
              <>
                <Popover
                  content={<video controls width={300} src={media.videoUrl} />}
                >
                  {media.videoUrl ? <PlayCircleOutlined className='text-black text-2xl mr-2' /> : null}
                </Popover>
                <DeleteOutlined className='text-black text-2xl' onClick={() => onDelete(media.id)} />
              </>
            }
          />
        </Card>
      ))}
    </div>
  );
};

export default MediaList;
