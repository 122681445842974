import React, { useState } from 'react';
import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { toast } from 'react-hot-toast';
import useAuthStore from '../../../authStore';
//import { likeById } from '../../../api'; 
import { throttle } from '../../../grocery/utils';

interface MediaCover {
  id: number;
  cover: string;
  englishName?: string;
  name: string;
  price: string;
  type?: string;
  islike?: boolean;
  isMylike?: boolean;
  totalLikes?: number;
}

interface MediaCoverCardProps {
  media: MediaCover;
  index: number;
  showName?: boolean;
  setShowPreview?: (flag: boolean) => void;
  setCurrentIndex?: (flag: number) => void;
  updateList?: (id: number, num: number) => void;
}

const MediaCoverCard: React.FC<MediaCoverCardProps> = ({ media, index, showName = true, setShowPreview, setCurrentIndex, updateList }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);
  const { user } = useAuthStore();
  const navigate = useNavigate();

/*   const handleLike = async () => {
    if (!user?.uid) {
      toast.error('Please log in to like the media');
      return;
    }
    const res = await likeById({ wid: media?.id, uid: user?.uid });
    if (res.data === 'liked') {
      toast.success('Liked');
      updateList?.(media?.id, 1);
    } else if (res.data === 'unliked') {
      toast.success('Unliked');
      updateList?.(media?.id, -1);
    } else {
      toast.error(res.data);
    }
  }; */

  return (
    <div className="relative cursor-pointer inline-block rounded-lg overflow-hidden mb-4 w-full max-w-[240px] min-w-[240px] h-[200px]">
      <Image
        className="rounded-lg contrast-115 object-cover w-full h-full"
        src={media?.cover}
        alt={media?.name}
        onLoad={() => setImgLoad(true)}
        onError={() => setImgLoad(false)}
        onClick={() => navigate(`/info/${media?.id}`)}
      />
      {!imgLoad && (
        <div className='bg-[#d0c9c9] w-full h-full skeleton rounded-lg'></div>
      )}
      {showName && (
        <div className="absolute bottom-0 left-0 right-0 bg-gray-800 bg-opacity-50 text-white p-2 rounded-b-lg z-10">
          <div className="flex justify-between">
            <div>
              <div
                onClick={() => navigate(`/info/${media?.id}`)}
                className="text-[0.8rem] md:text-[14px] text-left font-montserrat text-white p-0 md:pl-2"
              >
                <span>{media?.name}</span>
                {media?.price && (
                  <span className='text-[0.6rem] md:text-[12px] ml-2'>{media?.price}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    {/*   <div className="absolute top-2 right-2 z-10">
        {media.isMylike ? (
          <HeartFilled className="text-red-500" onClick={throttle(handleLike, 300)} />
        ) : (
          <HeartOutlined className="text-white" onClick={throttle(handleLike, 300)} />
        )}
      </div> */}
    </div>
  );
};

export default MediaCoverCard;

/* import React, { useState } from 'react';
import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { toast } from 'react-hot-toast';
import useAuthStore from '../../../authStore';
import { likeById } from '../../../api'; // 假设你有一个 API 方法来处理点赞
import { throttle } from '../../../grocery/utils';

interface MediaCover {
  id: number;
  cover: string;
  englishName?: string;
  name: string;
  price: string;
  type?: string;
  islike?: boolean;
  isMylike?: boolean;
  totalLikes?: number;
}

interface MediaCoverCardProps {
  media: MediaCover;
  index: number;
  showName?: boolean;
  setShowPreview?: (flag: boolean) => void;
  setCurrentIndex?: (flag: number) => void;
  updateList?: (id: number, num: number) => void;
}

const MediaCoverCard: React.FC<MediaCoverCardProps> = ({ media, index, showName = true, setShowPreview, setCurrentIndex, updateList }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);
  const { user } = useAuthStore();
  const navigate = useNavigate();

  const handleLike = async () => {
    if (!user?.uid) {
      toast.error('Please log in to like the media');
      return;
    }
    const res = await likeById({ wid: media?.id, uid: user?.uid });
    if (res.data === 'liked') {
      toast.success('Liked');
      updateList?.(media?.id, 1);
    } else if (res.data === 'unliked') {
      toast.success('Unliked');
      updateList?.(media?.id, -1);
    } else {
      toast.error(res.data);
    }
  };

  return (
    <div className="relative cursor-pointer inline-block rounded-lg overflow-hidden mb-0 md:mb-4 w-full">
      <Image
        className="rounded-lg contrast-115 object-cover w-full min-w-[42vw] md:min-w-[240px]"
        src={media?.cover}
        alt=''
        onLoad={() => setImgLoad(true)}
        onError={() => setImgLoad(false)}
        onClick={() => navigate(`/info/${media?.id}`)}
      />
      {imgLoad ? null : (
        <div>
          <div className='bg-[#d0c9c9] w-full pb-[58%] skeleton rounded-lg'></div>
        </div>
      )}
      {showName && (
        <div className="absolute bottom-0 left-0 right-0 bg-gray-800 bg-opacity-50 text-white p-2 rounded-b-lg z-10">
          <div className="flex justify-between">
            <div>
              <div
                onClick={() => navigate(`/info/${media?.id}`)}
                className="text-[0.8rem] md:text-[14px] text-left font-montserrat text-white p-0 md:pl-2"
              >
                <span>{media?.name}</span>
                {media?.price && (
                  <span className='text-[0.6rem] md:text-[12px] ml-2'>{media?.price}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaCoverCard; */