import React, { useState, useEffect } from 'react';

interface CardProps {
  imgSrc: string;
  imgAlt: string;
  date: string;
  jobTitle: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ imgSrc, imgAlt, date, jobTitle, description }) => (
  <article className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
    <div className="flex flex-col grow max-md:mt-10">
      <img loading="lazy" src={imgSrc} alt={imgAlt} className="w-full aspect-[1.52]" />
      <time className="mt-6 text-sm leading-7 text-orange-200">
        <span>{date} | </span>
        <span className="text-orange-200">{jobTitle}</span>
      </time>
      <h3 className="mt-5 text-xl font-medium leading-7 text-orange-100">{description}</h3>
    </div>
  </article>
);

const JobInformation: React.FC<{ id?: string }> = ({ id }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

/*   const cardsData = [
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_mama.jpg",
      imgAlt: "Female Waitress",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihoo Female Waitress"
    },
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_girls.jpg",
      imgAlt: "Club Chick Floor Lady",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihooクラブチック フロアレディ"
    },
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_bartender.jpg",
      imgAlt: "Male Waitress",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihoo Male Waitress"
    }
  ]; */

  const handleWebApplicationClick = () => {
    window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSdsKAQfu4CkHpA5OSoQBDCU6UAOFiTOyY6qawzMLcWBsDOCug/viewform?usp=sf_link";
  };

  return (
    <main id={id} className="flex flex-col px-5 bg-customPurple text-left">
      <header className={`self-center text-xl text-center text-orange-800 ${isMobile ? 'text-base mt-6' : 'text-3xl mt-6'}`}>Order</header>
      <h1 className={`self-center font-bold text-orange-100 leading-[77px] ${isMobile ? 'text-3xl leading-[40px] mt-0' : 'text-4xl mt-12'}`}>注文に関して</h1>
      <section className={`self-centertext-center text-white ${isMobile ? 'text-[20px] leading-6 mt-4 px-8' : 'text-2xl leading-9 mt-12 px-8'}`}>
        <p className='py-4'>AquaShop Tier1は、まだ始まったばかりの新しいお店ですので、現在は実店舗がありません。そのため、お魚に関するご相談やご注文は、GoogleフォームやTwitterからお気軽にどうぞ。</p>
        <p className='py-4'>お魚は郵送でお届けしますが、首都圏にお住まいの方であれば、距離に応じた配送料をいただければ、直接お届けすることも可能です。詳しい手続きについては、メールでお問い合わせください。</p>

        <p className='py-4'>死着補償は、お届け地域の最短到着時間をご指定いただいた場合のみ対応させていただきますので、ご了承ください。万が一、魚が死んだ状態で届いた場合は、到着から2時間以内に、袋を開封せずにデジカメなどで撮影した写真を先にお送りください。写真が不鮮明で状態が確認できない場合、補償ができないこともありますのでご注意ください。また、袋を開けた時点で補償が無効になりますので、その点もご注意ください。</p>
      </section>
      <h1 className={`self-center font-bold text-orange-100 leading-[77px] ${isMobile ? 'text-3xl leading-[40px] mt-0' : 'text-4xl mt-12'}`}>通信販売注意事項</h1>
      <section className={`self-centertext-center text-white ${isMobile ? 'text-base leading-6 mt-4 px-8' : 'text-2xl leading-9 mt-12 px-8'}`}>
        <p className='py-4'>死着補償は、お届け地域の最短到着時間をご指定いただいた場合のみ対応させていただきますので、ご了承ください。万が一、魚が死んだ状態で届いた場合は、到着から2時間以内に、袋を開封せずにデジカメなどで撮影した写真を先にお送りください。写真が不鮮明で状態が確認できない場合、補償ができないこともありますのでご注意ください。また、袋を開けた時点で補償が無効になりますので、その点もご注意ください。</p>
        <p className='py-4'>場合によっては、送料着払いで送り返していただくこともあります。代金の返金や代品の再送については、その時々の状況に応じて対応いたします。また、天候や運送会社の事情で到着が遅れてしまい、その結果死着だった場合は、補償の対象外となりますのでご了承ください。</p>
        <p className='py-4'>一方的なキャンセルやご連絡が取れない場合、次回からのご注文をお断りさせていただくことがございます。責任をもってご注文いただけますよう、よろしくお願い致します。</p>
        <p className='py-4'>到着した生体の状態が悪い場合は、その都度対応しますので、何かあればご相談ください</p>
      </section>
    {/*   <button 
        onClick={handleWebApplicationClick}
        className={`justify-center items-center px-2 py-1 mt-16 w-full font-bold text-white border-2 border-fuchsia-700 border-solid leading-[49.06px] rounded-[40px] ${isMobile ? 'text-xs px-2 py-1' : 'text-2xl'} max-md:px-2 max-md:py-1 max-md:mt-8 max-md:max-w-full`}
      >
        WEB応募 24 時間受付中
      </button> */}
      {/* <button className={`justify-center items-center px-2 py-1 mt-8 w-full font-bold text-white border-2 border-fuchsia-700 border-solid leading-[49.06px] rounded-[40px] ${isMobile ? 'text-xs px-2 py-1' : 'text-2xl'} max-md:px-2 max-md:py-1 max-md:mt-4 max-md:max-w-full`}>
        電話応募 TEL:03-4362-8777
      </button> */}
      <div className={`text-neutral-400 mt-12 mb-36 mx-auto leading-[49.06px] max-w-md ${isMobile ? 'text-xs' : 'text-2xl'}`}>
        電話 :preparing...
      </div>
    </main>
  );
}

export default JobInformation;





/* import React, { useState, useEffect } from 'react';

interface CardProps {
  imgSrc: string;
  imgAlt: string;
  date: string;
  jobTitle: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ imgSrc, imgAlt, date, jobTitle, description }) => (
  <article className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
    <div className="flex flex-col grow max-md:mt-10">
      <img loading="lazy" src={imgSrc} alt={imgAlt} className="w-full aspect-[1.52]" />
      <time className="mt-6 text-sm leading-7 text-orange-200">
        <span>{date} | </span>
        <span className="text-orange-200">{jobTitle}</span>
      </time>
      <h3 className="mt-5 text-xl font-medium leading-7 text-orange-100">{description}</h3>
    </div>
  </article>
);

const JobInformation: React.FC<{ id?: string }> = ({ id }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const cardsData = [
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_mama.jpg",
      imgAlt: "Female Waitress",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihoo Female Waitress"
    },
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_girls.jpg",
      imgAlt: "Club Chick Floor Lady",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihooクラブチック フロアレディ"
    },
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_bartender.jpg",
      imgAlt: "Male Waitress",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihoo Male Waitress"
    }
  ];

  const handleWebApplicationClick = () => {
    window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSdsKAQfu4CkHpA5OSoQBDCU6UAOFiTOyY6qawzMLcWBsDOCug/viewform?usp=sf_link";
  };

  return (
    <main id={id} className="flex flex-col px-5 bg-customPurple">
      <header className={`self-center text-xl text-center text-orange-800 ${isMobile ? 'text-lg' : 'text-xl'}`}>Recruit</header>
      <h1 className={`self-center mt-12 font-bold text-orange-100 leading-[77px] ${isMobile ? 'text-4xl leading-[50px]' : 'text-6xl'}`}>求人情報</h1>
      <section className={`self-center mt-16 text-center text-orange-100 ${isMobile ? 'text-lg leading-7' : 'text-2xl leading-9'}`}>
        <p>銀座のクラブで働いてみませんか？</p>
        <p>土地柄からお店には大手企業の役員、会社経営者など大人で紳士な方が多く、女の子も安心して働く事ができます。</p>
        <p>週1日からの勤務可能なので、学生さんやタレントのたまご、副業としてもおすすめです。</p>
      </section>
      <section className="px-px mt-16 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          {cardsData.map((card, index) => (
            <Card
              key={index}
              imgSrc={card.imgSrc}
              imgAlt={card.imgAlt}
              date={card.date}
              jobTitle={card.jobTitle}
              description={card.description}
            />
          ))}
        </div>
      </section>
      <button 
        onClick={handleWebApplicationClick}
        className={`justify-center items-center px-16 py-3.5 mt-16 w-full font-bold text-white border-2 border-fuchsia-700 border-solid leading-[49.06px] rounded-[40px] ${isMobile ? 'text-xl' : 'text-2xl'} max-md:px-5 max-md:mt-10 max-md:max-w-full`}
      >
        WEB応募 24 時間受付中
      </button>
      <button className={`justify-center items-center px-16 py-3.5 mt-8 w-full font-bold text-white border-2 border-fuchsia-700 border-solid leading-[49.06px] rounded-[40px] ${isMobile ? 'text-xl' : 'text-2xl'} max-md:px-5 max-md:max-w-full`}>
        電話応募 TEL:03-4361-9972
      </button>
      <div className={`text-2xl mt-12 mb-36 mx-auto leading-[49.06px] max-w-md text-neutral-400 ${isMobile ? 'text-xl' : 'text-2xl'}`}>
        電話受付時間 : 15~22時
      </div>
    </main>
  );
}

export default JobInformation;
 */