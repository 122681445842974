import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getArtistss } from '../../api';
import Header from '../../component/Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

const Info: React.FC = () => {
  const [artist, setArtist] = useState<{
    id: number;
    cover: string;
    name: string;
    price: string;
    type?: string;
    description: string;
    photo1: string;
    photo2: string;
  } | null>(null);
  const params = useParams();

  useEffect(() => {
    if (params?.animalId) {
      getArtistss({ id: params.animalId })
        .then((res: any) => {
          if (res?.data?.[0]) {
            setArtist(res.data[0]);
          }
        })
        .catch()
        .finally();
    }
  }, [params]);

  if (!artist) {
    return <div className="text-white">Loading...</div>;
  }

  return (
    <div className="max-w-[1440px] w-full mx-auto">
      <Header />
      <div className="bg-[rgba(0,0,0,0.7)] relative z-[1] p-4 md:px-16 lg:px-24 md:py-8">
        <div className="container mx-auto flex flex-col mr-36 ml-36 md:flex-row gap-8 items-center min-h-screen">
          
          <div className="md:w-1/2 max-w-md mx-auto">
            <Swiper
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
                renderBullet: (index, className) =>
                  `<span class="${className}" style="background-color: white; width: 12px; height: 12px;"></span>`,
              }}
              className="w-full"
            >
              <SwiperSlide>
                <img src={artist.cover} alt={artist.name} className="w-full h-auto object-cover max-h-[400px]" />
              </SwiperSlide>
              {artist.photo1 && (
                <SwiperSlide>
                  <img src={artist.photo1} alt={artist.name} className="w-full h-auto object-cover max-h-[400px]" />
                </SwiperSlide>
              )}
              {artist.photo2 && (
                <SwiperSlide>
                  <img src={artist.photo2} alt={artist.name} className="w-full h-auto object-cover max-h-[400px]" />
                </SwiperSlide>
              )}
            </Swiper>
          </div>

          <div className="md:w-1/2 flex flex-col gap-4 justify-center text-center md:text-left">
            <h1 className="text-4xl text-white">{artist.name}</h1>
            <p className="text-2xl text-white">{artist.price}</p>
            <p className="text-xl text-gray-300">{artist.description}</p>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Info;



/* import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getArtistss } from '../../api';
import Header from '../../component/Header';
import useAuthStore from '../../authStore';
import { contact } from '../../grocery/utils';

const Info: React.FC = () => {
  const [artist, setArtist] = useState<{
    id: number;
    cover: string;
    name: string;
    price: string;
    type?: string;
    description: string;
    photo1: string;
    photo2: string;
  } | null>(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params?.animalId) {
      getArtistss({ id: params.animalId })
        .then((res: any) => {
          if (res?.data?.[0]) {
            setArtist(res.data[0]);
          }
        })
        .catch()
        .finally();
    }
  }, [params]);

  if (!artist) {
    return <div className="text-white">Loading...</div>;
  }

  return (
    <div className="explore">
      <Header />
      <div className="fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]"></div>
      <div className="bg-[rgba(0,0,0,0.7)] relative z-[1]">
        <img
          className="w-full"
          src={artist.cover}
          alt={artist.name}
        />
      </div>

      <div className="relative p-4 z-10 flex flex-col justify-between bg-black w-full md:max-w-[1280px] lg:max-w-[1440px] mx-auto">
        <div className="mt-4 text-white">
          <h1 className="text-4xl md:text-6xl font-bold">{artist.name}</h1>
          <p className="text-2xl mt-4">{artist.price}</p>
          <p className="text-2xl mt-4">{artist.description}</p>
          {artist.type && <p className="text-xl mt-2">{artist.type}</p>}
        </div>
      </div>
    </div>
  );
};

export default Info;
 */