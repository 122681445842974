import { create } from 'zustand'

type modalStore = {
  isOpen: boolean;
  hideModal: () => void;
  showModal: () => void;
};

const useModalStore = create<modalStore>((set) => ({
  isOpen: false,
  hideModal: () => {
    set({ isOpen: false });
  },
  showModal: () => {
    set({ isOpen: true });
  }
}));

export default useModalStore;
