/* import React, { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuthStore from '../authStore';
import Header from './Header';
import BackGround from './BackGround';

interface AuthProps {
  allowedRoles: string[];
  children: ReactNode;
}

const Auth: React.FC<AuthProps> = ({ allowedRoles, children }) => {
  const { user, isLoggedIn } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  
  const { pathname } = location; // 保留原始 pathname
  // 检查用户是否未登录，并且访问的是需要登录的页面
  if (!isLoggedIn) {
    // 判断路径是否在允许未登录用户访问的列表中
    if (!['/', '/login', '/register', '/about', '/verify', '/pay', '/membership', '/terms', '/help', '/resetpw', '/privacy', '/usc', '/event', '/eventsub', '/fishType'].includes(pathname) && !pathname.startsWith('/info/')) {
      setTimeout(() => {
        navigate('/login');
      }, 3000);
      return (
        <div>
          <Header />
          <BackGround />
          <div className="flex flex-col justify-center items-center mt-[200px]">
            <h1 className="text-4xl font-montserrat text-white pl-2">Please Login</h1>
            <h1>Jump in 3 seconds</h1>
          </div>
        </div>
      );
    }
  }

  const hasPermission = user?.role && allowedRoles.includes(user.role);

  return hasPermission ? <>{children}</> : (
    <div>
      <Header />
      <BackGround />
      <div className="flex flex-col justify-center items-center mt-[200px] cursor-pointer" onClick={() => {
        navigate('/');
      }}>
        <h1 className="text-4xl font-montserrat text-white pl-2">No Permission</h1>
      </div>
    </div>
  );
};

export default Auth; */


import React, { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getLocalStorage } from '../grocery/utils';
import useAuthStore from '../authStore';
import Header from './Header';
import BackGround from './BackGround';
interface AuthProps {
  allowedRoles: string[];
  children: ReactNode;
}


const Auth: React.FC<AuthProps> = ({ allowedRoles, children }) => {
  const { user, isLoggedIn } = useAuthStore();
  const navigate = useNavigate()
  const location = useLocation()

  

  if (!isLoggedIn) {
    const { pathname } = location
    // if (!(allowedRoles.includes('guest'))) {
    if (!(['/', '/login', '/register', '/about', '/verify', '/pay', '/membership', '/terms', '/help', '/resetpw','/privacy','/usc', '/event', '/eventsub' , '/fishType' , '/info/:animalId'].includes(pathname))) {
      setTimeout(() => {
        navigate('/login')
      }, 3000)
      return (
        <div>
          <Header />
          <BackGround />
          <div className="flex flex-col justify-center items-center mt-[200px]">
            <h1 className='text-4xl font-montserrat  text-white pl-2'>Please Login</h1>
            <h1>Jump in 3 seconds</h1>
          </div>
        </div>
      )
    }
  }

  const hasPermission = user?.role && allowedRoles.includes(user.role);

  return hasPermission ? <>{children}</> : <div>
    <Header />
    <BackGround />
    <div className="flex flex-col justify-center items-center mt-[200px] cursor-pointer" onClick={() => {
      if (allowedRoles?.length !== 1) {
        navigate('/pay')
        return
      }
      navigate('/')
    }}>
      {allowedRoles?.length === 1 ? <h1 className='text-4xl font-montserrat  text-white pl-2'>Ask for adimin permission</h1> : <h1 className='text-4xl font-montserrat  text-white pl-2'>Please Pay</h1>}
      <h1>No Permission</h1>
    </div>
  </div>
};

export default Auth;