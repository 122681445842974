import React, { useState, useEffect, useCallback } from 'react';
import { getArtistsCoverByRandom } from '../../api';
//import SearchHeader from '../../component/SearchHeader';
import Header from '../../component/Header';
import { useSearchParams } from 'react-router-dom';
// import MediaList from '../../component/TypeFilterMediaList';
import FilterMediaCoverList from '../../component/TypeFilterMediaCoverList';
import TypeFilterMediaList from '../../component/TypeFilterMediaCoverList';
import useAuthStore from '../../authStore';

const MediaWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [mediaList, setMediaList] = useState<any[]>([]); // 确保初始状态是空数组
  const [searchParams, setSearchParams] = useSearchParams();
  const [typeFilter, setTypeFilter] = useState<string | undefined>(undefined);
  const { user } = useAuthStore();

  const fetchMoreData = async () => {
    if (!user?.uid) {
      return;
    }
    setLoading(true);
    const excludedIds = mediaList?.length ? mediaList.filter(i => (i?.id)).map(i => (i.id)) : [0];
    const sort = searchParams.get('sort') || 'rand';
    const type = searchParams.get('type') || undefined;
    try {
      const res = await getArtistsCoverByRandom({ orderBy: sort, excludedIds, limit: 20, type });
      console.log('frontend load data:', res);
      const newList = res?.data || [];
      const mlist = mediaList.concat(newList);
      setMediaList(mlist);
      console.log('fetch more data');
      /*if (Array.isArray(newList)) {
        setMediaList(prevList => [...prevList, ...newList]); // 确保追加数据是数组
      }*/
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const loadData = useCallback(async () => {
    if (!user?.uid) {
      console.log('no user id');
      return;
    }
    setLoading(true);
    const excludedIds = [0];
    const sort = searchParams.get('sort') || 'rand';
    const type = searchParams.get('type') || undefined;
    setTypeFilter(type);
    try {
      const res = await getArtistsCoverByRandom({ orderBy: sort, excludedIds, limit: 20, type });
      console.log('mediawall getArtistsCoverByRandom:', res);
      const newList = res?.data || [];
      console.log('new list in new function:',newList);
      console.log(`girlType: data: ${ JSON.stringify(res.data) }, newList: ${JSON.stringify(newList)}`)
      
      //const mlist = mediaList.concat(newList);
      /*if (Array.isArray(newList)) {
        setMediaList(newList); // 确保设置的数据是数组
      }*/
      //setMediaList(mlist);
      setMediaList(newList);
      //console.log('milst:',mlist);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [searchParams, user?.uid]);

  useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
  }, [searchParams, loadData]);

  return (
    <div className='max-w-[1440px] w-full mx-auto'>
      <Header /> 
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className='h-28 lg:h-32'></div>
      {/* {typeFilter && (
        <div>
          <p className='mx-auto text-left text-xl md:text-4xl text-white text-bold font-montserrat pb-4 max-w-[90rem] px-4'>
            type
            <span>「
              <span className='text-[#00F0FF]'>{typeFilter}</span>
              」
            </span>
            <span className='text-[#c4eb25] cursor-pointer ml-4' onClick={() => {
                setTypeFilter(undefined);
                setSearchParams(prevParams => {
                  const params = new URLSearchParams(prevParams);
                  params.delete('tag');
                  return params;
                });
              }}>X</span>
          </p>
        </div>
      )} */}
      {/*<TypeFilterMediaList mediaList={mediaList} setMediaList={setMediaList} fetchMoreData={fetchMoreData} loading={loading} />*/}
      <FilterMediaCoverList mediaList={mediaList} setMediaList={setMediaList} fetchMoreData={fetchMoreData} loading={loading} />
    </div>
  );
};

export default MediaWall;