import request from './request';

export const addAD = (data: any) => {
  return request({
    url: '/addAD',
    method: 'post',
    data
  });
}
export const modifyAD = (data: any) => {
  return request({
    url: '/modifyAD',
    method: 'post',
    data
  });
}
export const getAD = (params?: any) => {
  return request({
    url: '/getAD',
    method: 'get',
    params
  });
}