import React from 'react';
import { Table, Space, Button, Image, Popover } from 'antd';
import { useNavigate } from 'react-router-dom';
// interface AD {
//   id: string;
//   baner: string;
//   description: string;
//   avatar: string;
//   banner: string;
// }

interface DataTableProps {
  data: any[];
  onDelete: (id: string) => void;
  onEdit: (record: any) => void;
}

const DataTable: React.FC<DataTableProps> = ({ data, onDelete, onEdit }) => {
  const navigate = useNavigate()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'BG Color',
      dataIndex: 'bgcolor',
      key: 'bgcolor',
    },
    {
      title: 'Description',
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Image',
      dataIndex: 'banner',
      key: 'banner',
      render: (text: string) => (
        <Popover content={<Image width={200} src={text} />}>
          <Image width={50} src={text} />
        </Popover>
      ),
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text: string) => (
        <Popover content={<Image width={200} src={text} />}>
          <Image width={50} src={text} />
        </Popover>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button onClick={() => onEdit(record)}>Edit</Button>
          <Button onClick={() => onDelete(record.id)} danger>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      scroll={{ x: 'max-content' }}
      columns={columns}
      pagination={{ 
        pageSizeOptions: ['10', '25', '50'],
        showSizeChanger: true,
      }} 
    />
  );
};

export default DataTable;
