// import { useAuthStore } from '@store/auth';
// import { message } from 'antd';
import axios from 'axios';

export const service = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_HOST,
  timeout: 60000,
});


export const verificationCode = (data: any) => {
  return service({
    url: '/verificationCode',
    method: 'post',
    data
  });
}

export const sendVerificationCode = (data: any) => {
  return service({
    url: '/sendVerificationCode',
    method: 'post',
    data
  });
}

export const sendResetCode = (data: any) => {
  return service({
    url: '/sendResetCode',
    method: 'post',
    data
  });
}


export const isMailExist = (data: any) => {
  return service({
    url: '/isMailExist',
    method: 'post',
    data
  });
}