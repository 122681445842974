import request from './request';

// Event API
export const addEvent = (data: any) => {
  return request({
    url: '/addEvent',
    method: 'post',
    data
  });
};

export const getEvents = (params?: any) => {
  if (params) {
    return request({
      url: '/getEvents',
      method: 'get',
      params
    });
  }
  return request({
    url: '/getEvents',
    method: 'get'
  });
};

export const removeEvent = (data: any) => {
  return request({
    url: '/removeEvent',
    method: 'post',
    data
  });
};


export const getEventsCoverByRandom = (data?: any) => {
  return request({
    url: '/getEventsCoverByRandom',  // 更新URL以获取Cover数据
    method: 'post',
    data
  });
};

