// src/components/EventDataTable.tsx
import React from 'react';
import { Table, Space, Button, Image, Popover, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

interface Event {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  //en_description: string; // 新增的字段
  cover: string;
}

interface EventDataTableProps {
  data: Event[];
  onDelete: (id: string | number) => void;
  onEdit: (record: Event) => void;
}

const EventDataTable: React.FC<EventDataTableProps> = ({ data, onDelete, onEdit }) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search title"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <button onClick={() => confirm()}>Search</button>
          <button onClick={() => clearFilters()}>Reset</button>
        </div>
      ),
      onFilter: (value: any, record: Event) => record?.title.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Subtitle',
      dataIndex: 'subtitle',
      key: 'subtitle',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search subtitle"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <button onClick={() => confirm()}>Search</button>
          <button onClick={() => clearFilters()}>Reset</button>
        </div>
      ),
      onFilter: (value: any, record: Event) => record?.subtitle.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text: string) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '200px' }}>
          {text}
        </div>
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search description"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <button onClick={() => confirm()}>Search</button>
          <button onClick={() => clearFilters()}>Reset</button>
        </div>
      ),
      onFilter: (value: any, record: Event) => record?.description.toLowerCase().includes(value.toLowerCase()),
    },
/*     {
      title: 'English Description', // 新增的列
      dataIndex: 'en_description',
      key: 'en_description',
      render: (text: string) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '200px' }}>
          {text}
        </div>
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search English description"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <button onClick={() => confirm()}>Search</button>
          <button onClick={() => clearFilters()}>Reset</button>
        </div>
      ),
      onFilter: (value: any, record: Event) => record?.en_description.toLowerCase().includes(value.toLowerCase()),
    }, */
    {
      title: 'Cover',
      dataIndex: 'cover',
      key: 'cover',
      render: (text: string) => (
        <Popover content={<Image width={200} src={text} />}>
          <Image width={50} src={text} />
        </Popover>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: Event) => (
        <Space size="middle">
          <Button onClick={() => onEdit(record)}>Edit</Button>
          <Button onClick={() => onDelete(record.id)} danger>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: 'max-content' }}
      pagination={{
        pageSizeOptions: ['10', '25', '50'],
        showSizeChanger: true,
      }}
    />
  );
};

export default EventDataTable;

/* // src/components/EventDataTable.tsx
import React from 'react';
import { Table, Space, Button, Image, Popover, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

interface Event {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  en_description: string; // 新增的字段
  cover: string;
}

interface EventDataTableProps {
  data: Event[];
  onDelete: (id: string | number) => void;
  onEdit: (record: Event) => void;
}

const EventDataTable: React.FC<EventDataTableProps> = ({ data, onDelete, onEdit }) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search title"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <button onClick={() => confirm()}>Search</button>
          <button onClick={() => clearFilters()}>Reset</button>
        </div>
      ),
      onFilter: (value: any, record: Event) => record?.title.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Subtitle',
      dataIndex: 'subtitle',
      key: 'subtitle',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search subtitle"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <button onClick={() => confirm()}>Search</button>
          <button onClick={() => clearFilters()}>Reset</button>
        </div>
      ),
      onFilter: (value: any, record: Event) => record?.subtitle.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search description"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <button onClick={() => confirm()}>Search</button>
          <button onClick={() => clearFilters()}>Reset</button>
        </div>
      ),
      onFilter: (value: any, record: Event) => record?.description.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'English Description', // 新增的列
      dataIndex: 'en_description',
      key: 'en_description',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search English description"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <button onClick={() => confirm()}>Search</button>
          <button onClick={() => clearFilters()}>Reset</button>
        </div>
      ),
      onFilter: (value: any, record: Event) => record?.en_description.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Cover',
      dataIndex: 'cover',
      key: 'cover',
      render: (text: string) => (
        <Popover content={<Image width={200} src={text} />}>
          <Image width={50} src={text} />
        </Popover>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: Event) => (
        <Space size="middle">
          <Button onClick={() => onEdit(record)}>Edit</Button>
          <Button onClick={() => onDelete(record.id)} danger>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: 'max-content' }}
      pagination={{
        pageSizeOptions: ['10', '25', '50'],
        showSizeChanger: true,
      }}
    />
  );
};

export default EventDataTable; */