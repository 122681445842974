import React, { useState, useEffect } from 'react';

import { getOrder } from '../../../api'
import DataTable from './_components/DataTable';
import NavigationButtons from '../_components/NavigationButtons';
function Data() {
  const [orderList, setOrderList] = useState([])
  const loadData = () => {
    getOrder().then((res) => {
      setOrderList(res?.data)
    }).catch().finally()
  }
  useEffect(() => {
    loadData()
  }, [])
  return (
    <div className="Artwork bg-white min-h-[100vh] p-4 pb-[100px]">
      <div className='mb-2'>
        <NavigationButtons />
      </div>
      <DataTable data={orderList} />
    </div>
  );
}

export default Data;
